@import url("https://fonts.googleapis.com/css?family=Playfair+Display|Poppins:300,400,600,700,800&display=swap&subset=latin-ext");

/* BODY */
* {
    outline: none !important;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    color: $color-dark;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

/* HTML ELEMENT */
img {
    max-width: 100%;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

/* CUSTOM CLASSES */
.overflow {
    overflow: hidden;
}

/* LINKS */
a {
    @include transition;
    color: $color-dark;
}

a:hover {
    text-decoration: underline;
}

/* CUSTOM CONTAINER */
// .container {
//     max-width: 1280px;
// }

/* FORM ELEMENTS */
input[type="text"] {
    width: 100%;
    height: 54px;
    border: 1px solid #eee;
    padding: 0 20px;
}

textarea {
    width: 100%;
    height: 160px;
    border: 1px solid #eee;
    padding: 15px 20px;
}

button[type="submit"] {
    height: 54px;
    border: none;
    background: $color-brown;
    color: $white;
    padding: 0 30px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
}

/* ODOMETER */
.odometer {
    line-height: 1;
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
    padding: 0;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
    padding: 0;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
    left: 0;
}

/* ACCORDION */
.accordion {
    width: 100%;
    float: left;
    background: $white;
    padding: 0;

    .card {
        background: none;
        border-radius: 0;
        margin-top: -1px;
        border: 1px solid #eaebee;

        .card-header {
            background: none;
            padding: 0;
            border-bottom: none;

            a {
                width: 100%;
                float: left;
                font-weight: 500;
                padding: 20px 25px;
                color: $color-dark;
                font-weight: 600;

                &:before {
                    content: "+";
                    float: left;
                    text-align: center;
                    margin-right: 8px;
                    color: $color-dark;
                    font-weight: 600;
                }

                &:hover {
                    color: $color-brown;
                    text-decoration: none;
                }
            }
        }

        .card-body {
            width: 100%;
            border-top: 1px solid #eee;
            line-height: 26px;
        }

        [aria-expanded="true"] {
            color: $link;
            > i {
                transform: rotate(180deg);
            }
        }
    }
}

/* PAGINATION */
.pagination {
    display: flex;

    .page-item {
        display: inline-block;
        margin-right: 6px;

        &.active .page-link {
            background: $primary;
            color: $white;
            border-color: transparent;
            position: relative;
            z-index: 2;

            &:hover {
                background: $primary;
                border-color: transparent;
                color: $white;
            }
        }

        .page-link {
            display: inline-block;
            border-radius: 5px;
            padding: 7px 10px;
            color: $color-dark;
            font-weight: 600;
            font-size: 13px;
            border: 1px solid #dee2e6;
            line-height: 1;

            &:hover {
                background: none;
                border-color: #dee2e6;
                color: $primary;
            }
        }
    }
}

/* GALLERY SLIDER*/
.gallery-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    text-align: center;
    margin: 30px 0;
    background: $color-dark;

    .swiper-wrapper {
        width: 100%;
        float: left;
        position: relative;
    }

    .swiper-slide {
        width: 80%;
        margin: 0;
        opacity: 0.6;

        &.swiper-slide-active {
            width: 80%;
            opacity: 1;
        }

        img {
            width: 100%;
        }
    }

    .gallery-pagination {
        bottom: 20px;
        position: absolute;
        z-index: 2;

        .swiper-pagination-bullet {
            background: $white;
            opacity: 0.5;

            &.swiper-pagination-bullet-active {
                background: $color-light;
                opacity: 1;
            }
        }
    }
}

/* VIDEO BG */
.video-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    @include gradient-bg;

    video {
        min-width: 100%;
        min-height: 100%;
        float: left;
        opacity: 0.15;
    }
}

/* NAV PILLS */
.nav-pills {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    .nav-item {
        display: inline-block;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }

        .nav-link {
            background: none;
            color: $color-dark;
            border-bottom: 2px solid transparent;
            padding: 4px 0;
            border-radius: 0;
            font-weight: 600;
            font-size: 14px;

            &.active {
                border-bottom: 2px solid $color-dark;
            }
        }
    }
}

/* BREADCRUMB */
.breadcrumb {
    width: 100%;
    padding: 0;
    border-radius: 0;
    background: none;

    .breadcrumb-item {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        padding: 0;

        &:first-child:before {
            display: none;
        }

        &:before {
            content: "»";
            color: $white;
            font-weight: 400;
            padding: 0 10px;
        }

        &.active {
            color: $white;
            opacity: 1;
        }

        a {
            color: $white;
            opacity: 0.75;
        }
    }
}

/* HAMBURGER */
.hamburger {
    position: relative;
    cursor: pointer;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition-duration: 500ms;
    -webkit-transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);

    span {
        display: block;
        height: 2px;
        width: 30px;
        background: $white;
        opacity: 1;
        position: absolute;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 9px;
        }

        &:nth-child(3) {
            width: 10px;
            top: 19px;
        }

        &:nth-child(4) {
            width: 6px;
            top: 19px;
            left: 15px;
        }
    }

    &.open {
        margin: 0;

        span {
            &:nth-child(1) {
                top: 9px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
            }

            &:nth-child(2) {
                opacity: 0;
                left: 20px;
            }

            &:nth-child(3) {
                width: 30px;
                top: 9px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }

            &:nth-child(4) {
                opacity: 0;
                left: 20px;
            }
        }
    }

    &:hover span:nth-child(4) {
        width: 20px;
        left: 10px;
    }
}

/* PRELAODER */
.preloader {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 12;
    background: $color-light;
    @include transition-cubic;
    transition-delay: 0.95s;
    overflow: hidden;

    * {
        @include transition-cubic;
    }

    .layer {
        width: 100%;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        background: #333;
        transition-delay: 0.3s;
    }

    .inner {
        display: inline-block;
        text-align: center;
        position: relative;
        z-index: 2;

        figure {
            display: block;
            margin-bottom: 10px;
            transition-delay: 0.1s;

            img {
                height: 60px;
            }
        }

        p {
            font-weight: 600;
            color: $white;
            font-size: 13px;
            display: block;
        }
    }
}

/* TRANSITION OVERLAY */
.transition-overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 12;
    background: $color-light;
    @include transition-cubic;
    overflow: hidden;

    .layer {
        width: 100%;
        height: 100vh;
        position: absolute;
        right: -100%;
        top: 0;
        background: #333;
        @include transition-cubic;
        transition-delay: 0.6s;
    }

    &.active {
        left: 0;

        .layer {
            right: 0;
        }
    }
}

/* PAGE LOADED */
.page-loaded .preloader {
    right: -100%;
}

.page-loaded .preloader .inner figure {
    opacity: 0;
    transform: scale(1.5);
}

.page-loaded .preloader .inner p {
    transform: translateY(20px);
    opacity: 0;
}

.page-loaded .preloader .layer {
    left: -100%;
}

.page-loaded .navbar .container .upper-side {
    transform: translateY(0);
}

.page-loaded .navbar .container .menu {
    opacity: 1;
}

.page-loaded .slider .slider-container {
    transform: scale(1);
    opacity: 1;
}

/* SIDE NAVIGATION */
.side-navigation {
    width: 400px;
    max-width: 100%;
    height: 100%;
    min-height: calc(100vh - 74px);
    min-height: 400px;
    position: fixed;
    left: -100%;
    top: 74px;
    background: $color-dark;
    z-index: 10;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
    color: $white;
    font-size: 14px;
    padding: 100px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include transition-cubic;
    z-index: 999;
    overflow-y: scroll;

    @include breakpoint-down(sm) {
        overflow-y: scroll;
    }

    .menu {
        margin-bottom: 20px;

        ul {
            width: 100%;
            margin: 0;
            padding: 0;

            li {
                width: 100%;
                display: block;
                margin: 0;
                padding: 8px 0;
                list-style: none;
                position: relative;

                ul {
                    width: 100%;
                    display: none;
                    padding-left: 20px;
                    margin-bottom: 10px;

                    li {
                        display: block;

                        a {
                            font-size: 2vw;
                        }
                    }
                }

                a:not(.btn) {
                    display: inline-block;
                    color: $white;
                    font-size: 16px;
                    font-weight: 600;

                    &:hover {
                        color: #a4d6ff;
                        text-decoration: none;
                    }
                }

                &.active {
                    a:not(.btn) {
                        color: #a4d6ff;
                    }
                }

                .btn {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }

    .side-content {
        width: 100%;
        display: block;
    }

    address {
        display: block;
        margin-bottom: 20px;
    }

    h6 {
        font-weight: 600;
        font-size: 14px;
    }

    p {
        display: block;
        margin-bottom: 30px;

        a {
            color: $white;
        }
    }

    &.active {
        left: 0;
    }
}

/* NAVBAR */
.navbar {
    position: relative;
    z-index: 3;
    background-color: $danger;
    transition: 0.5s all;
    padding: 1rem;

    &:not(.navbar-transparent) {
        animation: fade-bottom 0.5s forwards;
    }

    &.navbar-transparent {
        background: transparent !important;

        &:after {
            display: none;
        }

        .navbar-brand .bw {
            display: block;
        }

        .navbar-brand .normal {
            display: none;
        }

        .navbar-brand:after {
            opacity: 0;
        }
    }

    &:after {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.75;
        background-image: url(../images/pattern_bg.svg);
        background-size: 300px;
        background-blend-mode: color;
        background-color: $danger;
        z-index: -1;
    }

    .navbar-nav {
        width: 100%;
        justify-content: end;
        padding: 0 2vw;
        gap: 1.25vw;

        .nav-item {
            margin: 0;
            margin-left: 1rem;
            position: relative;
            align-self: center;

            .nav-link {
                position: relative;
                font-weight: 600;
                color: $white;
                display: inline-block;
                padding: 0;
                font-size: 1.15vw;
                white-space: nowrap;
                opacity: 0.75;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    height: 2px;
                    background-color: $white;
                }

                &:hover {
                    &:after {
                        content: "";
                        animation: 0.5s toHalfWidth forwards
                            cubic-bezier(0.075, 0.82, 0.165, 1);
                    }
                }
            }

            .btn {
                font-size: 1.15vw;
                padding: 0.675rem 1rem;
            }

            &.active {
                .nav-link {
                    font-weight: 700;
                    opacity: 1;

                    &:after {
                        opacity: 1;
                        width: 50%;
                    }
                }
            }
        }
    }

    .navbar-brand {
        position: relative;
        margin-left: 1rem;

        img {
            height: 50px;
            position: relative;
            z-index: 1;
            transition: 0.5s all;
        }

        .bw {
            display: none;
        }

        .normal {
            display: block;
            transform: translateY(8px);
        }

        &:after {
            content: "";
            position: absolute;
            background: $white;
            height: 85px;
            width: 120%;
            top: 57%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 12px 12px 0 0;
        }
    }

    .navbar-toggler {
        margin: auto;
        margin-right: 0;
        height: 20px;
    }
}

/* SLIDER */
.slider {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-wrap: wrap;
    background: $color-dark;
    overflow: hidden;

    @include breakpoint-up(md) {
        height: calc(100vh - 94px);
    }

    .slider-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        @include transition-cubic;
        transform: scale(1.3);
        transition-delay: 1.25s;
        opacity: 0;

        .swiper-slide {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            align-content: center;
            background-size: cover;
            background-repeat: no-repeat;

            &:after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: linear-gradient(
                    161deg,
                    #26282b 0%,
                    #26282b 49%,
                    #303030 100%
                );
                opacity: 0.35;
            }

            .container {
                position: relative;
                z-index: 9;
                padding-left: 100px;

                @include breakpoint-down(md) {
                    text-align: center;
                }

                h2 {
                    font-size: 2rem;
                    font-weight: 600;
                    color: $white;
                    @include second-font;

                    @include breakpoint-up(md) {
                        margin-bottom: 20px;
                    }
                }

                p {
                    color: #fff;
                    opacity: 0.75;
                    margin-bottom: 1rem;

                    @include breakpoint-up(md) {
                        margin-bottom: 25px;
                        max-width: 70%;
                        font-size: 1.25rem;
                    }
                }

                .btn {
                    font-size: 1rem;
                    padding: 0.65rem 2rem;
                    color: white;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-weight: 700;
                    border-radius: 7px;
                    font-family: "Playfair Display", serif;
                }

                figure {
                    float: left;
                    margin-left: 40px;
                    margin-bottom: 0;

                    img {
                        height: 60px;
                    }
                }
            }
        }

        .inner-elements {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;

            .pagination {
                width: auto;
                position: absolute;
                left: auto;
                right: 1rem;
                bottom: 17%;
                z-index: 10;
                color: $white;
                font-size: 14px;
                font-weight: 600;
                line-height: 50px;

                @include breakpoint-up(md) {
                    bottom: 20px;
                    top: unset;
                }

                .swiper-pagination-current {
                    font-size: 40px;
                    font-weight: 800;
                    margin-right: 5px;
                }

                .swiper-pagination-total {
                    font-size: 20px;
                    margin-left: 5px;
                }
            }

            .button-prev {
                width: 80px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 4;
                transition: 0.25s ease-in-out;
                color: #fff;
                font-size: 14px;
                line-height: 1;
                cursor: pointer;
                font-size: 2rem;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    color: pink;

                    &:after {
                        background: linear-gradient(
                            to right,
                            #00000069,
                            transparent
                        );
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    height: 100%;
                    left: 0;
                    width: 80px;
                    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                    background: linear-gradient(
                        to right,
                        #00000033,
                        transparent
                    );
                }
            }

            .button-next {
                width: 80px;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 4;
                transition: 0.25s ease-in-out;
                color: #fff;
                font-size: 14px;
                line-height: 1;
                cursor: pointer;
                font-size: 2rem;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    color: pink;

                    &:after {
                        background: linear-gradient(
                            to left,
                            #00000069,
                            transparent
                        );
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    height: 100%;
                    right: 0;
                    width: 80px;
                    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                    background: linear-gradient(
                        to left,
                        #00000033,
                        transparent
                    );
                }
            }
        }
    }
}

/* PAGE HEADER */
.page-header {
    width: 100%;
    height: 350px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1080px;

    @include breakpoint-up(md) {
        height: 500px;
        background-position: center;
        background-size: cover;
    }

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(
            161deg,
            #26282b 0%,
            #26282b 49%,
            #303030 100%
        );
        opacity: 0.35;
    }

    .container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 2;
        padding-left: 5vw;

        h1 {
            font-size: 4vw;
            color: $white;
            @include second-font;
            margin-bottom: 20px;
            margin-bottom: 0;
        }

        p {
            display: block;
            color: $white;
            opacity: 0.75;
            font-size: 0.875rem;
            margin: 0;

            @include breakpoint-up(md) {
                font-size: 1rem;
            }
        }
    }

    .breadcrumb {
        position: absolute;
        width: 90%;
        margin: 5vw;
        top: 92.5%;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
        padding: 1rem 2rem;
        background: linear-gradient(to left, #3e3e3e, #212121);
        z-index: 3;

        @include breakpoint-up(md) {
            padding: 2rem 3rem;
            padding-right: 60px;
            top: 90%;
        }

        li {
            font-size: 0.75rem;

            @include breakpoint-up(md) {
                font-size: 14px;
            }
        }
    }
}

/* INTRO */
.intro {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;

    .img-box {
        position: relative;
        margin-bottom: 2rem;

        img {
            box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
            width: 100%;
            margin-bottom: 0;

            @include breakpoint-up(md) {
                width: 80%;
            }
        }

        &:after {
            content: "";
            position: absolute;
            height: 110%;
            width: 80%;
            background: whitesmoke;
            left: 50%;
            top: -5%;
            transform: translateX(-50%);
            z-index: -1;

            @include breakpoint-up(md) {
                height: 100%;
                top: 5%;
                left: 5%;
                transform: none;
            }
        }
    }

    .content-box {
        h3 {
            font-weight: 600;
            margin-bottom: 30px;
        }

        p {
            display: block;
            margin-bottom: 30px;
        }
    }
}

/* LOGOS */
.logos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 100px 0;

    * {
        @include transition;
    }

    .col-lg-2 {
        border-right: 1px dashed #eee;

        &:last-child {
            border-right: none;
        }
    }

    figure {
        width: 100%;
        margin: 0;
        position: relative;

        &:hover img {
            opacity: 0;
            transform: scale(1.2);
        }

        &:hover h6 {
            opacity: 1;
            transform: scale(1);
        }

        img {
            width: 100%;
        }

        h6 {
            width: 100%;
            height: 20px;
            line-height: 20px;
            margin: 0;
            position: absolute;
            top: 50%;
            margin-top: -10px;
            text-align: center;
            opacity: 0;
            font-weight: 800;
            font-size: 13px;
            transform: scale(1.1);
        }
    }
}

/* BENEFITS */
.benefits {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;
    background: #f9f9f9;
    text-align: center;

    .col {
        border-right: 1px solid #eee;
        padding-top: 20px;
        padding-bottom: 20px;

        &:nth-child(odd) {
            margin-top: 50px;
        }

        &:hover figure:after {
            opacity: 1;
        }

        &:last-child {
            border-right: none;
        }
    }

    b {
        display: block;
        font-size: 30px;
        font-weight: 800;
        opacity: 0.2;
    }

    h4 {
        display: block;
        font-size: 30px;
        @include second-font;
        margin-bottom: 15px;

        span {
            color: $color-brown;
        }
    }

    h3 {
        font-weight: 600;
        margin-bottom: 50px;
    }

    figure {
        display: block;
        margin-bottom: 20px;
        position: relative;

        &:after {
            content: "";
            width: 70px;
            height: 70px;
            position: absolute;
            left: 50%;
            top: 0;
            background: $color-light;
            border-radius: 50%;
            opacity: 0;
            z-index: 0;
            margin-left: -35px;
        }

        img {
            height: 70px;
            position: relative;
            z-index: 2;
        }
    }

    h6 {
        font-weight: 600;
        font-size: 14px;
    }

    .odometer {
        font-size: 50px;
        line-height: 1;
        font-weight: 800;
    }

    .extra {
        font-size: 18px;
        font-weight: 600;
        display: inline-block;
        transform: translateY(10px);
    }
}

.review {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;
    position: relative;
    background: linear-gradient(0.86deg, #cc2024 0.86%, #eb3136 99.38%);
    color: $white;

    &:after,
    &:before {
        content: "";
        position: absolute;
        height: 100px;
        width: 100px;
        background-size: cover;
        background-repeat: no-repeat;
        mix-blend-mode: multiply;
        background-image: url("data:image/svg+xml,%3Csvg width='150' height='150' viewBox='0 0 150 150' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:multiply' clip-path='url(%23clip0_50_246)'%3E%3Cpath d='M64.8092 13.168C38.2289 15.0357 0.021 19.2795 0 71.3358V136.832H59.313V66.7558H39.6183C38.3706 48.0442 53.7787 43.2126 70.5342 39.5037L64.8092 13.168V13.168ZM144.275 13.168C117.694 15.0357 79.4865 19.2796 79.4656 71.3358V136.832H138.779V66.7558H119.084C117.836 48.0442 133.244 43.2126 150 39.5037L144.275 13.168V13.168Z' fill='%23EC363B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_50_246'%3E%3Crect width='150' height='150' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }

    &:after {
        top: 0;
        left: 0.75rem;
    }

    &:before {
        bottom: 0;
        right: 0.75rem;
        transform: rotate(180deg);
    }

    .card {
        border-color: rgba($white, 24%);
    }

    .avatar {
        height: 100px;
        width: 100px;
        margin-bottom: 0.5rem;
        overflow: hidden;
        border-radius: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    h4 {
        font-weight: 700;
    }

    p {
        font-size: 14px;
    }
}

/* RECENT GALLERY */
.recent-gallery,
.services {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;

    b {
        display: block;
        font-size: 30px;
        font-weight: 800;
        opacity: 0.2;
    }

    h4 {
        display: block;
        font-size: 30px;
        @include second-font;
        margin-bottom: 15px;

        span {
            color: $color-brown;
        }
    }

    h3 {
        font-weight: 600;
        padding-right: 40%;
        margin-bottom: 30px;
    }

    .link {
        display: inline-block;
        color: $color-dark;
        font-weight: 600;
        font-size: 14px;
        position: relative;
        padding-bottom: 10px;

        i {
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;

            &:before {
                width: 100%;
            }
        }

        &:before {
            content: "";
            width: 60px;
            height: 2px;
            background: $color-dark;
            position: absolute;
            left: 0;
            bottom: 0;
            @include transition;
        }
    }

    figure {
        display: block !important;
        margin: 0;
        position: relative;
    }

    .col-md-4:nth-child(1) {
        margin-top: 100px;
    }

    .col-md-4:nth-child(2) {
        margin-top: 0;
    }

    .col-md-4:nth-child(3) {
        margin-top: 80px;
    }
}

/* PROPERTY CALCULATOR */
.property-calculator {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 150px;
    margin-top: 100px;
    position: relative;

    figure {
        display: inline-block;
        margin-bottom: 0;
        margin-top: -20px;
        background: $color-light;
        position: relative;

        &:before {
            content: "";
            width: 50vw;
            height: 100%;
            background: $color-light;
            position: absolute;
            left: -50vw;
            top: 0;
        }

        .pattern-bg {
            width: 120%;
            height: 100%;
            position: absolute;
            left: 5%;
            top: -5%;
            background: url(../images/side-pattern-bg.png);
            background-size: contain;
        }

        .holder {
            position: relative;
            width: 100%;
            float: left;
            display: block !important;
            transform: translate(85px, -35px);

            img {
                box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
                width: 525px;
            }
        }
    }

    .content-box {
        display: block;
        padding-left: 10%;

        b {
            display: block;
            font-size: 30px;
            font-weight: 800;
            opacity: 0.2;
        }

        h4 {
            display: block;
            font-size: 30px;
            @include second-font;
            margin-bottom: 15px;

            span {
                color: $color-brown;
            }
        }

        h3 {
            font-weight: 600;
            margin-bottom: 30px;
        }

        ul {
            display: block;
            margin-bottom: 60px;
            padding: 0;

            li {
                display: inline-block;
                margin: 0;
                padding: 0;
                list-style: none;

                &:nth-child(1) {
                    margin-right: 30px;
                }

                img {
                    height: 35px;
                }
            }
        }

        p {
            display: block;
            padding-right: 40%;
            margin-bottom: 30px;
        }

        a {
            display: inline-block;
            color: $color-dark;
            font-weight: 600;

            img {
                height: 46px;
                margin-right: 10px;
            }

            &:hover {
                color: $color-brown;
                text-decoration: none;
            }
        }
    }
}

/* PROPERTY PLANS */
.property-plans {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 150px;

    b {
        display: block;
        font-size: 30px;
        font-weight: 800;
        opacity: 0.2;
    }

    h4 {
        display: block;
        font-size: 30px;
        @include second-font;
        margin-bottom: 15px;

        span {
            color: $color-brown;
        }
    }

    h3 {
        font-weight: 600;
        margin-bottom: 30px;
    }

    p {
        display: block;
        padding-right: 40%;
        margin-bottom: 30px;
    }

    table {
        display: table;
        margin-bottom: 30px;
        font-size: 15px;

        tr td {
            padding: 5px 0;
        }

        tr td:first-child {
            color: $color-brown;
            width: 120px;
            font-weight: 600;
        }
    }

    .tab-nav {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        li {
            display: inline-block;
            margin: 0;
            margin-right: 10px;
            padding: 0;
            list-style: none;

            a {
                color: $color-dark;
                font-weight: 600;
            }
        }
    }
}

/* GET CONSULTATION */
.get-consultation {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 150px;
    margin-bottom: 100px;
    background-size: auto 120%;
    background-position: right;
    background-repeat: no-repeat;
    background-color: #e5e4df;

    .content-box {
        width: 500px;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 80px 50px;
        margin-bottom: -100px;
        color: $white;
        background: $color-brown;
        position: relative;
        overflow: hidden;

        &:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 100px 200px 100vh 0;
            border-color: transparent $color-light transparent transparent;
            position: absolute;
            right: 0;
            top: 0;
            opacity: 0.15;
        }
    }

    b {
        width: 100%;
        display: block;
        font-size: 30px;
        font-weight: 800;
        opacity: 0.2;
    }

    h4 {
        display: block;
        font-size: 30px;
        @include second-font;
        margin-bottom: 15px;

        span {
            color: $color-light;
        }
    }

    h3 {
        font-weight: 600;
        margin-bottom: 30px;
    }

    p {
        display: block;
        padding-right: 20%;
        margin-bottom: 40px;
    }

    a {
        height: 60px;
        line-height: 60px;
        float: left;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 10px;
        position: relative;
        background: $color-dark;
        padding: 0 40px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
        @include transition;

        i {
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;
            background: $white;
            color: $color-brown;
        }
    }
}

/* RECENT POSTS */
.recent-posts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;

    b {
        display: block;
        font-size: 30px;
        font-weight: 800;
        opacity: 0.2;
    }

    h4 {
        display: block;
        font-size: 30px;
        @include second-font;
        margin-bottom: 15px;

        span {
            color: $color-brown;
        }
    }

    small {
        display: block;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 40px;
        opacity: 0.5;

        &:after {
            content: "";
            width: 50px;
            height: 1px;
            background: $color-dark;
            display: inline-block;
            margin-left: 5px;
            margin-bottom: 4px;
            opacity: 0.5;
        }
    }

    .post-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        figure {
            display: block;
            margin-bottom: 20px;
        }

        h6 {
            display: block;
            font-size: 20px;
            font-weight: 600;

            a {
                display: inline-block;

                &:hover {
                    color: $color-brown;
                    text-decoration: none;
                }
            }
        }

        p {
            display: block;
            margin: 0;
        }

        span {
            width: 100%;
            display: block;
            font-size: 13px;
            opacity: 0.5;
            margin-bottom: 10px;
        }
    }
}

/* PROPERTY CUSTOMIZATION */
.property-customization {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;
    background: $color-dark;
    color: $white;
    position: relative;

    b {
        width: 100%;
        display: block;
        font-size: 30px;
        font-weight: 800;
        opacity: 0.2;
    }

    h4 {
        display: block;
        font-size: 30px;
        @include second-font;
        margin-bottom: 15px;

        span {
            color: $color-light;
        }
    }

    h3 {
        font-weight: 600;
        margin-bottom: 30px;
    }

    figure {
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 15px 10px;
        margin: 15px 0;

        img {
            height: 40px;
            float: left;
            margin-right: 15px;
        }

        figcaption {
            color: $white;
            font-size: 13px;
            font-weight: 600;
        }
    }
}

/* APARTMENTS */
.apartment {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;

    h2 {
        font-size: 4vw;
        @include second-font;
        margin-bottom: 20px;

        span {
            color: $color-brown;
        }
    }

    h6 {
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 20px;
    }

    h5 {
        margin-bottom: 70px;
        font-weight: 600;
        opacity: 0.5;
    }

    h4 {
        margin-bottom: 20px;
        font-weight: 600;
    }

    p {
        line-height: 1.7;
        margin-bottom: 20px;

        strong {
            font-weight: 600;
        }
    }

    ul {
        width: 100%;
        display: block;
        margin-bottom: 30px;
        padding-left: 20px;

        li {
            margin: 0;
            padding: 4px 0;
        }
    }

    blockquote {
        display: inline-block;
        padding: 30px;
        background: $color-light;
        margin-bottom: 40px;

        p {
            margin: 0;
        }

        strong {
            font-size: 20px;
        }
    }

    figure {
        width: 100%;
        display: block;
        margin-bottom: 70px;
        margin-top: 50px;
    }

    .gallery-slider {
        width: 80%;
        margin-top: 50px;
        margin-bottom: 70px;

        .gallery-container {
            margin: 0;
        }
    }

    .property-plan {
        display: inline-block;
    }

    .property-infos {
        display: inline-block;
        background: $color-brown;
        color: $white;
        padding: 50px;
        margin-bottom: 50px;
        float: left;
        margin-right: 100px;

        .odometer {
            font-size: 60px;
            font-weight: 800;
            position: relative;
            display: inline-flex;

            &:after {
                content: attr(data-text);
                font-size: 30px;
                font-weight: 600;
                margin-top: 10px;
                margin-left: 5px;
                opacity: 0.5;
            }
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}

/* ABOUT CONTENT */
.about-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 80px 0;

    h2 {
        font-size: 4vw;
        margin-bottom: 20px;
        font-weight: 700;

        span {
            color: $color-brown;
        }
    }

    h6 {
        margin-bottom: 15px;
        font-size: 20px;
    }

    h5 {
        margin-bottom: 40px;
        opacity: 0.5;
    }

    h4 {
        margin-bottom: 20px;
    }

    p {
        line-height: 1.7;
        margin-bottom: 20px;

        strong {
            font-weight: 600;
        }
    }

    ul {
        width: 100%;
        display: block;
        margin-bottom: 30px;
        padding-left: 20px;

        li {
            margin: 0;
            padding: 4px 0;
        }
    }

    blockquote {
        display: inline-block;
        padding: 30px;
        background: $color-light;
        margin-bottom: 40px;

        p {
            margin: 0;
        }

        strong {
            font-size: 20px;
        }
    }

    .video-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;
        margin-top: 30px;

        video {
            width: 100%;
            height: 100%;
            float: left;
        }
    }

    .gallery-container {
        margin-bottom: 50px;
    }

    .card-with-icon {
        border: none;
        transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &:hover {
            transform: translateY(-10px);
            background: #f6fbff;
            box-shadow: 0 10px 20px #5d4ed314;
        }
    }
}

/* CERTIFICATES */
.certificates {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;

    b {
        display: block;
        font-size: 30px;
        font-weight: 800;
        opacity: 0.2;
    }

    h4 {
        display: block;
        font-size: 30px;
        @include second-font;
        margin-bottom: 15px;

        span {
            color: $color-brown;
        }
    }

    small {
        display: block;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 40px;
        opacity: 0.5;

        &:after {
            content: "";
            width: 50px;
            height: 1px;
            background: $color-dark;
            display: inline-block;
            margin-left: 5px;
            margin-bottom: 4px;
            opacity: 0.5;
        }
    }

    figure {
        display: block;
        margin: 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        img {
            width: 100%;
        }
    }
}

/* FACILITIES */
.facilities {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 135px 0;

    b {
        display: block;
        font-size: 30px;
        font-weight: 800;
        opacity: 0.2;
    }

    h4 {
        display: block;
        font-size: 30px;
        @include second-font;
        margin-bottom: 15px;

        span {
            color: $color-brown;
        }
    }

    small {
        display: block;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 40px;
        opacity: 0.5;

        &:after {
            content: "";
            width: 50px;
            height: 1px;
            background: $color-dark;
            display: inline-block;
            margin-left: 5px;
            margin-bottom: 4px;
            opacity: 0.5;
        }
    }

    figure {
        width: 100%;
        float: left;
        margin: 15px 0;
        padding: 50px 30px;
        box-shadow: 0 0 20px rgba(235, 207, 167, 0.4);

        img {
            height: 70px;
            float: left;
        }

        figcaption {
            width: calc(100% - 90px);
            float: right;

            h5 {
                font-weight: 600;
                font-size: 16px;
            }

            p {
                font-size: 14px;
                opacity: 0.5;
                margin: 0;
            }
        }
    }
}

/* FAQ */
.faq {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;

    .sidebox {
        width: calc(100% - 70px);
        float: left;
        margin-left: 70px;
        padding: 40px 30px;
        text-align: center;
        background: $color-brown;
        color: $white;
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;

        i {
            font-size: 100px;
            margin-bottom: 20px;
            display: inline-block;
        }

        h3 {
            display: block;
            @include second-font;
            margin-bottom: 20px;
        }

        p {
            color: $white;
        }
    }
}

/* SALES OFFICES */
.sales-offices {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 135px 0;

    .office-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 30px;
        margin: 15px 0;
        border: 1px solid #eee;

        h5 {
            @include second-font;
            margin-bottom: 20px;
            font-size: 26px;
        }

        address {
            margin-bottom: 25px;
        }

        a {
            height: 44px;
            line-height: 44px;
            display: inline-block;
            padding: 0 20px;
            background: $color-dark;
            font-size: 13px;
            font-weight: 600;
            color: $white;

            i {
                margin-right: 7px;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}

/* INTRO VIDEO */
.intro-video {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;
    text-align: center;

    b {
        display: block;
        font-size: 30px;
        font-weight: 800;
        opacity: 0.2;
    }

    h4 {
        display: block;
        font-size: 30px;
        @include second-font;
        margin-bottom: 15px;

        span {
            color: $color-brown;
        }
    }

    h3 {
        font-weight: 600;
        margin-bottom: 50px;
    }

    .video-container {
        width: 100%;
        float: left;
        margin: 0;
        padding: 5px;
        background: $color-light;

        video {
            width: 100%;
            float: left;
        }
    }
}

/* PRESS RELEASES */
.press-relases {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 135px 0;

    figure {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 15px 0;
        border: 1px solid #eee;

        a {
            width: 40%;

            img {
                width: 100%;
            }
        }

        figcaption {
            width: 60%;
            padding-left: 20px;

            h5 {
                display: block;
                font-size: 22px;
                @include second-font;
                margin-bottom: 15px;
            }

            p {
                font-size: 14px;
            }

            small {
                display: block;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-bottom: 0;
                opacity: 0.5;
            }
        }
    }
}

/* PHOTO GALLERY */
.photo-gallery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;

    .gallery-filter {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
        padding: 0;

        li {
            display: inline-block;
            margin-right: 25px;
            padding: 0;
            list-style: none;

            a {
                font-weight: 600;
                border-bottom: 2px solid transparent;

                &.current {
                    border-bottom: 2px solid $color-light;
                }

                &:hover {
                    color: $color-brown;
                    text-decoration: none;
                }
            }
        }
    }

    .gallery {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        margin-left: -4px;
        margin-right: -4px;
        padding: 0;

        li {
            width: 25%;
            margin: 0;
            padding: 4px;
            list-style: none;

            img {
                width: 100%;
                float: left;
            }
        }
    }
}

/* SALES TEAM */
.sales-team {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;

    figure {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border: 1px solid #eee;
        margin: 15px 0;

        img {
            width: 50%;
            display: inline-block;
        }

        figcaption {
            display: inline-block;

            h4 {
                display: block;
                font-size: 30px;
                @include second-font;
                margin-bottom: 15px;

                span {
                    color: $color-brown;
                }
            }

            small {
                display: block;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-bottom: 40px;
                opacity: 0.5;

                &:after {
                    content: "";
                    width: 50px;
                    height: 1px;
                    background: $color-dark;
                    display: inline-block;
                    margin-left: 10px;
                    margin-bottom: 4px;
                    opacity: 0.5;
                }
            }

            ul {
                display: block;
                margin: 0;
                padding: 0;

                li {
                    display: inline-block;
                    margin: 0;
                    margin-right: 15px;
                    padding: 0;
                    list-style: none;

                    a {
                        font-size: 14px;
                        font-weight: 600;

                        i {
                            margin-right: 6px;
                            font-size: 12px;
                            font-weight: 400;
                        }

                        &:hover {
                            text-decoration: none;
                            color: $color-brown;
                        }
                    }
                }
            }
        }
    }
}

/* ERROR 404*/
.error404 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;
    text-align: center;

    figure {
        display: block;
        margin-bottom: 60px;
        padding: 0 10%;

        img {
            width: 100%;
        }
    }

    h2 {
        font-size: 50px;
        font-weight: 800;
        color: $color-brown;
    }

    p {
        display: block;
        margin-bottom: 40px;
    }

    a {
        height: 60px;
        line-height: 56px;
        display: inline-block;
        color: $color-brown;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 10px;
        position: relative;
        border: 2px solid $color-light;
        padding: 0 40px;
        box-shadow: 0 0 20px rgba(235, 207, 167, 0.4);
        @include transition;

        &:hover {
            text-decoration: none;
            color: $white;
            background: $color-brown;
            border-color: $color-brown;
        }
    }
}

/* BLOG */
.blog {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;

    .post {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 100px;
        position: relative;
        flex-direction: column;

        .post-content {
            width: calc(100% - 60px);
            float: left;
            background: $white;
            margin: 0 30px;
            margin-top: -100px;
            padding: 30px;
            box-shadow: 0 0 25px rgb(0 0 0 / 5%);

            &.single {
                width: 100%;
                margin: 0;
                margin-top: 50px;
                padding: 0;
            }

            .post-title {
                font-weight: 700;

                a {
                    color: $color-dark;

                    &:hover {
                        color: $dark-color;
                        text-decoration: none;
                    }
                }
            }

            .post-tags {
                width: 100%;
                float: left;

                i {
                    font-size: 19px;
                    float: left;
                    margin-right: 10px;
                    color: $color-brown;
                    margin-top: 4px;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0;

                    li {
                        float: left;
                        margin-right: 10px;
                        padding: 0;
                        list-style: none;
                        margin-bottom: 12px;

                        a {
                            color: $color-dark;
                            text-decoration: underline;
                        }
                    }
                }
            }

            ul {
                width: 100%;
                display: block;
                margin-bottom: 30px;
                padding-left: 20px;

                li {
                    margin: 0;
                    padding: 4px 0;
                }
            }

            blockquote {
                display: inline-block;
                padding: 30px;
                background: $color-light;
                margin-bottom: 40px;

                p {
                    margin: 0;
                }

                strong {
                    font-size: 20px;
                }
            }

            .post-intro {
                width: 100%;
                float: left;
                margin-bottom: 30px;
                font-size: 0.875rem;
                line-height: 1.6;
            }

            .post-author {
                line-height: 60px;

                span {
                    opacity: 0.5;
                    margin-right: 5px;
                    font-style: italic;
                }

                a {
                    color: $color-dark;
                }
            }
        }
    }

    .sidebar {
        width: calc(100% - 50px);
        display: flex;
        flex-wrap: wrap;
        margin-left: 50px;
        border-left: 1px solid #eee;
        padding-left: 50px;
        margin-bottom: 100px;
        position: sticky;
        top: 120px;

        .widget {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #eee;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }

            .title {
                font-weight: 600;
                letter-spacing: -1px;
                margin-bottom: 20px;
            }

            .form-control {
                border-radius: 13px 0 0 13px;
            }

            .categories {
                width: 100%;
                display: block;
                margin: 0;
                padding: 0;

                li {
                    width: 100%;
                    display: block;
                    margin: 0;
                    padding: 2px 0;
                    list-style: none;

                    &:before {
                        content: "»";
                        margin-right: 9px;
                        color: $dark-color;
                    }

                    span {
                        float: right;
                        color: $dark-color;
                    }

                    a {
                        color: $color-dark;

                        &:hover {
                            color: $dark-color;
                        }
                    }
                }
            }
        }
    }
}

/* CONTACT */
.contact {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;

    b {
        display: block;
        font-size: 30px;
        font-weight: 800;
        opacity: 0.2;
    }

    h4 {
        display: block;
        font-size: 30px;
        @include second-font;
        margin-bottom: 15px;

        span {
            color: $color-brown;
        }
    }

    address {
        display: block;
        margin-bottom: 50px;

        strong {
            font-weight: 600;
            display: block;
            margin-bottom: 10px;
        }

        p {
            display: block;
            margin: 0;
        }
    }

    .contact-form {
        width: 100%;
        display: block;
        padding-left: 10%;

        h5 {
            margin-bottom: 40px;
        }

        #contact {
            display: block;

            .form-group {
                display: block;
                position: relative;
                margin-bottom: 10px;

                input[type="text"] {
                    background: none;
                    position: relative;
                    z-index: 2;
                }

                textarea {
                    background: none;
                    position: relative;
                    z-index: 2;
                }

                label.error {
                    color: red;
                    margin-top: 5px;
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 13px;
                }
            }
        }

        .alert {
            border: none;
            border-radius: 0;
            padding: 20px 30px;
        }

        .btn {
            padding: 1rem 2rem;
            display: inline-flex;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .spinner-wrapper {
            margin-left: 0.5rem;
            margin-right: -10px;
        }
    }
}

/* FOOTER BAR */
.float-card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 120px;
    padding-bottom: 100px;
}

.main-card {
    margin-top: -90px;
    background: transparent;
    margin-bottom: 2rem;

    .card-header {
        border-radius: 13px 13px 0 0;

        ul {
            margin: 0;
            padding: 0.5rem 0;

            @include breakpoint-down(sm) {
                overflow-x: scroll;
                overflow-y: hidden;

                &::-webkit-scrollbar {
                    height: 3px;
                }

                &::-webkit-scrollbar-track {
                    background: $danger-hover;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #ad2124;
                    border-radius: 10px;
                }
            }

            li {
                position: relative;
                padding: 0 1rem 0 0.25rem;
                list-style: none;

                &:not(:last-child) > :after {
                    content: "";
                    position: absolute;
                    height: 1rem;
                    width: 2px;
                    background-color: white;
                    opacity: 0.75;
                    right: 3px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                a {
                    color: $white;
                    text-decoration: none;
                    white-space: nowrap;
                    opacity: 0.75;
                }

                &.active {
                    a {
                        opacity: 1;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        height: 4px;
                        width: 80%;
                        background: #ffb4b6;
                        left: 45%;
                        transform: translateX(-50%);
                        bottom: -25px;
                    }
                }
            }
        }
    }

    .main-card-body {
        border-radius: 0 0 13px 13px;
        background-color: white;
        padding: 3vmin 4vmin;
        transform-origin: top;
        transition: 0.2s all;

        &.skeleton {
            padding-top: 10rem;

            & > * {
                display: none;
            }
        }
    }
}

.form-with-input-group {
    .form-group .btn {
        width: 100%;

        @include breakpoint-up(md) {
            width: unset;
        }
    }

    @include breakpoint-up(md) {
        .input-group .input-group-text {
            background: $danger;
            color: $white;
        }
    }

    @include breakpoint-down(md) {
        .input-group {
            flex-flow: column;

            .input-group-text {
                background: none;
                padding: 0;
            }

            .form-control,
            .btn {
                border-radius: 12px;
            }

            .input-group-prepend,
            .form-control {
                width: 100%;
                margin-bottom: 0.5rem;
            }

            .btn {
                width: 100%;
                margin-top: 0.25rem;
                padding: 12px;
            }
        }
    }
}

.agent-row {
    max-height: 400px;
    overflow-y: scroll !important;
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background: $super-light;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: $light;
        border-radius: 10px;
    }
}

.media {
    flex-direction: column;
    padding: 2rem 1rem;
    border-radius: 12px;

    img {
        margin-bottom: 1rem;

        @include breakpoint-up(md) {
            width: 50%;
            max-width: 300px;
        }
    }

    .nav-tabs {
        padding: 0;
        border: 0;

        .nav-link {
            border: 0;
            display: inline-flex;
            border-radius: 4px;

            &.active {
                background-color: $primary;
                color: $white;
            }
        }
    }

    p {
        margin-bottom: 0.5rem;
    }

    .tab-pane {
        p {
            margin-bottom: 0.5rem;
        }
    }

    &.service {
        border: 1px solid #e9e9e9;

        @include breakpoint-up(md) {
            flex-direction: row;
        }

        img {
            width: 100%;

            @include breakpoint-up(md) {
                width: 50%;
                max-width: 300px;
                margin-right: 1rem;
            }
        }

        p {
            margin-bottom: 0.5rem;

            @include breakpoint-up(md) {
                max-width: 75%;
            }
        }
    }
}

a[href] {
    cursor: pointer;
}

.card-agent {
    height: 100%;
    background-color: $white;
    border: 0;
    border-radius: 8px;

    .card-body {
        padding: 1rem;

        h6 {
            font-size: 0.875rem;
            font-weight: 600;
        }
    }

    .card-footer {
        padding-top: 0;
        background: #f7f7f742;
        border: 0;

        .btn {
            padding: 0.5rem 0.75rem;
            color: $white;
        }
    }
}

.slider-promo,
.slider-services {
    width: 100%;
    height: 100%;
}

.slider-promo {
    .splide__arrows {
        position: relative;
        top: 40%;
        transform: translateY(-50%);
        z-index: 9;
    }

    .splide__pagination {
        bottom: 10%;

        @include breakpoint-up(md) {
            bottom: 15%;
        }

        .splide__pagination__page {
            background: rgba($danger, 0.4);

            &.is-active {
                background: $danger-hover;
            }
        }
    }

    .splide__arrow--prev {
        left: -1em;
    }

    .splide__arrow--next {
        right: -1em;
    }
}

.slider-services {
    margin-bottom: 2rem;

    .splide__slide {
        position: relative;

        p {
            position: absolute;
            margin: 0;
            width: 100%;
            bottom: -35px;
            opacity: 0;
            left: 0%;
            text-align: center;
            transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            padding: 1rem 0.25rem;
            background: linear-gradient(
                to top,
                rgba(0, 0, 0, 0.6) 0,
                rgba(0, 0, 0, 0) 100%
            );
            color: $white;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        &:hover p {
            opacity: 1;
            bottom: 0;
        }
    }
}

.slider-review {
    .splide__pagination {
        bottom: -20%;

        .splide__pagination__page {
            background: rgba($white, 0.4);

            &.is-active {
                background: $white;
            }
        }
    }
}

/* FOOTER */
.footer {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 100px;
    padding-bottom: 30px;
    background: $color-dark;

    &:after {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        background-image: url(../images/pattern_bg.svg);
        background-size: 300px;
        background-blend-mode: luminosity;
        background-color: $color-dark;
        z-index: 0;
    }

    .container {
        z-index: 1;
    }

    a {
        color: $light-color;

        &:hover {
            color: $white;
        }
    }

    p {
        color: $light-color;
    }

    .logo {
        height: 41px;
        margin-bottom: 15px;

        img {
            height: 48px;
        }
    }

    .footer-menu {
        display: block;
        margin: 0;
        padding-left: 10px;

        li {
            position: relative;
            width: 100%;
            float: left;
            margin: 0;
            padding: 4px 0 4px 15px;
            list-style: none;

            &:after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.63317 10C1.37151 9.99846 1.11311 9.94167 0.874915 9.83335C0.617295 9.71979 0.397806 9.53448 0.242662 9.29955C0.087518 9.06462 0.00327723 8.78999 0 8.50848V1.49249C0.00327723 1.21098 0.087518 0.936353 0.242662 0.701421C0.397806 0.466489 0.617295 0.281179 0.874915 0.167623C1.17134 0.02761 1.50113 -0.0263174 1.82671 0.0119863C2.15229 0.0502899 2.46057 0.179284 2.7164 0.384269L6.96599 3.89226C7.13264 4.02493 7.26722 4.19347 7.35972 4.38534C7.45222 4.57722 7.50025 4.78748 7.50025 5.00049C7.50025 5.21349 7.45222 5.42376 7.35972 5.61563C7.26722 5.8075 7.13264 5.97604 6.96599 6.10871L2.7164 9.6167C2.41008 9.86512 2.02756 10.0005 1.63317 10Z' fill='white'/%3E%3C/svg%3E");
                position: absolute;
                width: 10px;
                height: 10px;
                background-repeat: no-repeat;
                background-size: 7px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0.8;
            }
        }
    }

    h5 {
        margin-bottom: 10px;
        font-weight: 700 !important;
    }

    .contact-box {
        color: $white;

        p {
            margin-bottom: 10px;

            a {
                color: $white;
                opacity: 0.5;
            }
        }

        a i {
            font-size: 16px;
        }
    }

    .copyright-container {
        margin-top: 2rem;
        padding: 0.5rem 1rem;

        hr {
            margin-bottom: 2rem;
            border-top: 1.5px solid #565656;
        }

        .copyright {
            color: $white;
        }
    }

    .social-media {
        float: none;
        display: flex;
        justify-content: center;
        margin: 0;

        @include breakpoint-up(md) {
            justify-content: start;
        }
    }

    .social-media li a {
        background: rgb(5 91 163 / 50%);
        font-size: 20px;
    }
}

/* RESPONSIVE MEDIUM  FIXES */
@media only screen and (max-width: 1199px),
    only screen and (max-device-width: 1199px) {
    .intro figure .holder {
        transform: translate(65px, -65px);
    }

    .intro figure .holder img {
        width: 450px;
    }

    .property-calculator figure .holder {
        transform: translate(65px, -65px);
    }

    .property-calculator figure .holder img {
        width: 450px;
    }

    .footer-bar .inner p {
        font-size: 15px;
    }

    .footer-bar .inner p br {
        display: none;
    }
}

/* RESPONSIVE TABLET FIXES */
@media only screen and (max-width: 991px),
    only screen and (max-device-width: 991px) {
    .navbar .container .menu {
        display: none;
    }

    .page-header .container h1 {
        font-size: 28px !important;
    }

    .intro img {
        width: 100%;
        margin-bottom: 2rem;
    }

    .intro .content-box {
        padding-left: 0;
    }

    .logos img {
        padding: 0 10%;
    }

    .about-content {
        padding: 25px 0;
    }

    .sales-offices {
        padding: 80px 0;
    }

    .press-relases {
        padding: 80px 0;
    }

    .photo-gallery {
        padding: 80px 0;
    }

    .photo-gallery .gallery li {
        width: 33.33333%;
    }

    .intro-video {
        padding: 80px 0;
    }

    .faq .sidebox {
        width: 100%;
        margin-left: 0;
        margin-top: 50px;
    }

    .apartment {
        padding: 80px 0;
    }

    .apartment .property-infos {
        width: 100%;
        margin-right: 0;
    }

    .apartment .property-plan {
        margin: 50px 0;
    }

    .apartment .gallery-slider {
        width: 100%;
    }

    .facilities {
        padding: 80px 0;
    }

    .blog .sidebar {
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        border-left: none;
    }

    .sales-team {
        padding: 80px 0;
    }

    .sales-team figure figcaption {
        width: 50%;
    }

    .sales-team figure figcaption h4 {
        font-size: 21px;
    }

    .sales-team figure figcaption small {
        margin-bottom: 20px;
    }

    .error404 {
        padding: 80px 0;
    }

    .recent-gallery {
        padding: 80px 0;
    }

    .recent-gallery h3 {
        padding-right: 0;
    }

    .recent-gallery .col-md-4 {
        margin-top: 0 !important;
    }

    .recent-gallery figure {
        margin-top: 80px;
    }

    .benefits {
        padding: 80px 0;
    }

    .property-calculator {
        padding-bottom: 80px;
        margin-top: 0;
    }

    .property-calculator .col-lg-6:nth-child(1) {
        order: 2;
    }

    .property-calculator .col-lg-6:nth-child(2) {
        order: 1;
    }

    .property-calculator figure {
        width: 100%;
    }

    .property-calculator figure:before {
        display: none;
    }

    .property-calculator figure .pattern-bg {
        width: 100%;
        left: 0;
    }

    .property-calculator figure .holder {
        transform: translate(0, -45px);
        padding: 0 15%;
    }

    .property-calculator figure .holder img {
        width: 100%;
    }

    .property-calculator .content-box {
        padding-left: 0;
        margin-bottom: 150px;
    }

    .property-plans {
        padding-bottom: 80px;
    }

    .property-plans .nav-pills {
        margin-top: 40px;
    }

    .recent-posts {
        padding: 80px 0;
    }

    .recent-posts .post-box {
        margin: 25px 0;
    }

    .property-customization {
        padding: 80px 0;
    }

    .video-bg video {
        float: right;
    }

    .certificates {
        padding: 80px 0;
    }

    .contact {
        padding: 80px 0;
    }

    .contact address p a {
        display: block;
    }

    .contact .contact-form {
        padding-left: 0;
        margin-top: 50px;
    }

    .blog {
        padding: 80px 0;
    }

    .footer-bar .inner {
        padding: 30px;
    }

    .footer-bar .inner .col-lg-4 {
        margin: 20px 0;
    }

    .footer-bar .inner figure {
        width: 70px;
        margin-right: 0;
    }

    .footer-bar .inner figure img {
        height: 50px;
    }

    .footer {
        padding-top: 150px;
    }

    .footer .select-box {
        margin-bottom: 80px;
    }

    .footer .footer-menu {
        padding: 0;
    }

    .footer .contact-box {
        float: left;
        text-align: left;
    }

    .footer .contact-box ul {
        float: left;
    }
}

/* RESPONSIVE MOBILE FIXES */
@media only screen and (max-width: 767px),
    only screen and (max-device-width: 767px) {
    .side-navigation {
        width: 80vw;
    }

    .page-header .container h1 {
        font-size: 36px;
    }

    .page-header .container .breadcrumb {
        width: calc(100% - 30px);
    }

    .navbar .container .upper-side .phone-email {
        display: none;
    }

    .navbar .container .upper-side .language {
        display: none;
    }

    .navbar .container .upper-side .hamburger {
        margin-left: auto;
    }

    .slider .slider-container .inner-elements .container .social-media {
        display: none;
    }

    .slider .slider-container .swiper-slide .container {
        padding-left: 15px;
        padding-right: 40px;
    }

    .slider .slider-container .swiper-slide .container figure {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
    }

    .benefits .col {
        width: 50%;
        flex-basis: auto;
        border-right: none;
    }

    .benefits .col:nth-child(odd) {
        margin-top: 0;
    }

    .apartment h2 {
        font-size: 9vw;
    }

    .apartment figure {
        margin: 15px 0;
    }

    .apartment h4 {
        margin-top: 30px;
    }

    .about-content h2 {
        font-size: 10vw;
    }

    .blog .post .post-image {
        margin-bottom: 30px;
    }

    .blog .post .post-content {
        margin: 0;
        width: 100%;
    }

    .contact .map .holder iframe {
        width: 100%;
        height: 450px;
    }

    .certificates figure {
        margin: 15px 0;
    }

    .footer .col-12 .creation {
        float: left;
        margin-top: 5px;
    }
}

.heading {
    position: relative;
    font-weight: 800 !important;
    text-align: center;

    @include breakpoint-down(md) {
        font-size: 1.5rem;
    }
}

.sub-heading {
    width: 100%;
    text-align: center;
    margin: auto;

    @include breakpoint-up(md) {
        max-width: 500px !important;
    }

    @include breakpoint-up(lg) {
        max-width: 750px !important;
    }
}

.select-box {
    display: inline-block;
    position: relative;
    height: 50px;
    line-height: 48px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding-right: 15px;
    padding-left: 25px;
    margin-top: 20px;

    &:hover {
        border: 1px solid rgba(255, 255, 255, 0.35);
    }

    span {
        margin-right: 40px;
    }

    .dropdown-toggle {
        color: $white;
        font-size: 14px;
        font-weight: 600;

        &:hover {
            text-decoration: none;
        }

        img {
            width: 20px;
            margin-right: 5px;
        }
    }

    .dropdown-menu {
        width: 100%;
        background: $white;
        border-radius: 0;
        border: none;
        margin-left: -25px;
        margin-top: 13px;

        li {
            display: block;
            margin: 0;
            padding: 0;
            list-style: none;

            a {
                display: block;
                padding: 7px 25px;
                font-size: 14px;
                line-height: 1;

                &:hover {
                    background: none;
                    color: $color-brown;
                }

                img {
                    width: 20px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.timeline {
    border-radius: 0;
    flex-direction: column-reverse;

    &.sm {
        max-height: 300px;
        overflow-y: scroll;
        padding-left: 1rem;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background: $light-color;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: $muted;
        border-radius: 5px;

        &:hover {
            background: $muted;
        }
    }

    .list-group-item {
        position: relative;
        border: 0;
        border-left: 4px solid $super-light;
        border-radius: 0 !important;

        &:after {
            content: "";
            position: absolute;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            top: 50%;
            left: -10px;
            animation: timeline-grow 0.75s linear;
        }

        &:last-child:after {
            transform: translateY(-50%) scale(0.5);
            background: $primary;
            border-color: $primary;
            animation-iteration-count: infinite;
        }

        &:after {
            transform: translateY(-50%) scale(1);
            background: $white;
            border: 2px solid $light-color;
            animation-fill-mode: forwards;
        }
    }
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.bg-image {
    margin-bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba($light-color, 0.3);
}

.ratio-16-9 {
    padding-top: 54%;
}

.ratio-1-1 {
    padding-top: 100%;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.p-center-bottom {
    background-position: center bottom;
}

.p-center-top {
    background-position: center top;
}

.p-center-center {
    background-position: center center;
}

.p-left-center {
    background-position: left center;
}

.p-left-top {
    background-position: left top;
}

.p-left-bottom {
    background-position: left bottom;
}

.p-right-center {
    background-position: right center;
}

.p-right-bottom {
    background-position: right bottom;
}

.p-right-top {
    background-position: right top;
}

.tnc {
    line-height: 1.5;
    padding-left: 2rem;

    li {
        padding: 0.25rem;
        border-radius: 8px;

        &:hover {
            background: rgba($light-color, 0.3);
        }

        ol {
            padding-left: 1.5rem;

            li {
                list-style-type: lower-alpha;
                padding: 0.25rem;
                opacity: 0.9;
            }
        }
    }
}

.card-with-icon {
    height: 100%;

    .icon {
        padding: 1rem;
        background: $primary;
        color: white;
        display: inline-flex;
        border-radius: 50%;
    }
}

.skeleton {
    background: linear-gradient(
        90deg,
        hsl(210, 15%, 88%),
        hsl(210, 15%, 95%),
        hsl(210, 15%, 88%)
    );
    background-size: 200%;
    animation: loader 1s infinite reverse;
}

h2.skeleton {
    width: 50%;
    height: 1.5em;
    border-radius: 8px;
}

small.skeleton {
    display: inline-flex;
    height: 1rem;
    width: 4rem;
    border-radius: 8px;
}

p.skeleton {
    height: 2em;
    border-radius: 8px;
}

.social-media {
    width: 100%;
    float: left;
    margin: 0;
    margin-bottom: 20px;
    padding: 0;

    li {
        float: left;
        margin-left: 5px;
        padding: 0;
        list-style: none;

        a {
            width: 40px;
            height: 40px;
            float: left;
            text-align: center;
            line-height: 42px;
            font-size: 15px;
            color: $white;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 8px;

            &:hover {
                background: $primary-hover;
            }
        }
    }
}
