@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Playfair+Display|Poppins:300,400,600,700,800&display=swap&subset=latin-ext");
@keyframes toHalfWidth {
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 50%;
  }
}
@keyframes timeline-grow {
  0% {
    opacity: 0.25;
  }
  50% {
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }
}
@keyframes loader {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}
@keyframes fade-bottom {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
/***************
 *  Typography *
 ***************/
.text-primary {
  color: #055CA4;
}

.text-secondary {
  color: #8B8B8B;
}

.text-success {
  color: #229E27;
}

.text-danger {
  color: #EB3136;
}

.text-warning {
  color: #EBB731;
}

.text-info {
  color: #22889E;
}

.text-light {
  color: #E5E5E5;
}

.text-dark {
  color: #323232;
}

.text-muted {
  color: #8B8B8B;
}

.text-white {
  color: #fff;
}

/***************
 *  Background *
 ***************/
.bg-primary {
  background-color: #055CA4 !important;
}

.bg-secondary {
  background-color: #8B8B8B !important;
}

.bg-success {
  background-color: #229E27 !important;
}

.bg-danger {
  background-color: #EB3136 !important;
}

.bg-warning {
  background-color: #EBB731 !important;
}

.bg-info {
  background-color: #22889E !important;
}

.bg-light {
  background-color: #F2F2F2 !important;
}

.bg-dark {
  background-color: #323232 !important;
}

.bg-muted {
  background-color: #8B8B8B !important;
}

.bg-white {
  background-color: #fff !important;
}

/***************
 *    Button   *
 ***************/
.btn {
  box-shadow: none;
  border: none;
  border-radius: 13px;
  padding: 12px 20px;
  font-size: 14px;
}
.btn i {
  font-size: 14px;
  transform: translateY(2px);
}

.btn-primary {
  background-color: #055CA4;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  box-shadow: none !important;
  background-color: #0B5089 !important;
}

.btn-secondary {
  background-color: #8B8B8B;
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  box-shadow: none !important;
  background-color: #716E6E !important;
}

.btn-success {
  background-color: #229E27;
}
.btn-success:hover, .btn-success:focus, .btn-success:active {
  box-shadow: none !important;
  background-color: #108B15 !important;
}

.btn-danger {
  background-color: #EB3136;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active {
  box-shadow: none !important;
  background-color: #CD171B !important;
}

.btn-warning {
  background-color: #EBB731;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  box-shadow: none !important;
  background-color: #E2B02E !important;
}

.btn-info {
  background-color: #22889E;
}
.btn-info:hover, .btn-info:focus, .btn-info:active {
  box-shadow: none !important;
  background-color: #167387 !important;
}

.btn-light {
  background-color: #E5E5E5;
}
.btn-light:hover, .btn-light:focus, .btn-light:active {
  box-shadow: none !important;
  background-color: #DBDBDB !important;
}

.btn-dark {
  background-color: #323232;
}
.btn-dark:hover, .btn-dark:focus, .btn-dark:active {
  box-shadow: none !important;
  background-color: #252525 !important;
}

.btn-outline-primary {
  color: #055CA4 !important;
  border: 1px solid #055CA4;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
  box-shadow: none !important;
  background-color: #0B5089 !important;
  border: 1px solid #055CA4;
  color: #fff !important;
}

.btn-outline-secondary {
  color: #8B8B8B !important;
  border: 1px solid #8B8B8B;
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active {
  box-shadow: none !important;
  background-color: #716E6E !important;
  border: 1px solid #8B8B8B;
  color: #fff !important;
}

.btn-outline-success {
  color: #229E27 !important;
  border: 1px solid #229E27;
}
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active {
  box-shadow: none !important;
  background-color: #108B15 !important;
  border: 1px solid #229E27;
  color: #fff !important;
}

.btn-outline-danger {
  color: #EB3136 !important;
  border: 1px solid #EB3136;
}
.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active {
  box-shadow: none !important;
  background-color: #CD171B !important;
  border: 1px solid #EB3136;
  color: #fff !important;
}

.btn-outline-warning {
  color: #EBB731 !important;
  border: 1px solid #EBB731;
}
.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active {
  box-shadow: none !important;
  background-color: #E2B02E !important;
  border: 1px solid #EBB731;
  color: #fff !important;
}

.btn-outline-info {
  color: #22889E !important;
  border: 1px solid #22889E;
}
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active {
  box-shadow: none !important;
  background-color: #167387 !important;
  border: 1px solid #22889E;
  color: #fff !important;
}

.btn-outline-light {
  color: #E5E5E5 !important;
  border: 1px solid #E5E5E5;
}
.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active {
  box-shadow: none !important;
  background-color: #DBDBDB !important;
  border: 1px solid #E5E5E5;
  color: #323232 !important;
}

.btn-outline-dark {
  color: #323232 !important;
  border: 1px solid #323232;
}
.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active {
  box-shadow: none !important;
  background-color: #252525 !important;
  border: 1px solid #323232;
  color: #fff !important;
}

input[type=text] {
  height: unset !important;
  padding: 12px 20px !important;
}

.form-control {
  font-size: 14px;
  border-color: #eee !important;
}
.form-control:focus, .form-control:active {
  box-shadow: none;
  border-color: rgba(139, 139, 139, 0.5);
}

.input-group .input-group-prepend {
  border-radius: 13px;
}
.input-group .input-group-text {
  border-radius: 13px;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border: 0;
}
.input-group .input-group-append {
  border-radius: 13px;
}

.close {
  font-weight: 400;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
}

.btn-close {
  box-sizing: content-box;
  width: 1em !important;
  height: 1em !important;
  padding: 0.25em 0.25em;
  color: #000;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' style='fill: rgba(0, 0, 0, 1);transform: ;msFilter:;'%3E%3Cpath d='m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z'%3E%3C/path%3E%3C/svg%3E");
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.alert {
  padding: 1rem 1.25rem !important;
}
.alert h6 {
  font-weight: 700;
  font-size: 1.1rem;
}

.modal#result .modal-header {
  border: 0;
}
.modal#result .modal-header h5 {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
}

.select2-container--bootstrap .select2-dropdown {
  border-color: #eee !important;
}

.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
  box-shadow: none !important;
}

.select2-container--bootstrap .select2-selection {
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #8B8B8B !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #eee !important;
  border-radius: 0.25rem !important;
  padding: 12px 20px !important;
  height: unset !important;
  box-shadow: none !important;
}

.select2-container--bootstrap .select2-results__option[aria-selected=true] {
  background-color: #EB3136 !important;
  color: #fff !important;
}

.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
  background-color: #CD171B !important;
  color: #fff !important;
}

.select2-container--bootstrap .select2-results__option {
  padding: 8px 12px !important;
  border-top: 1px solid #eeeeee47 !important;
}

.select2-container--bootstrap .select2-dropdown {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05) !important;
}

.select2-container--bootstrap .select2-search--dropdown .select2-search__field {
  box-shadow: none !important;
  padding: 8px 12px !important;
  border-color: #eee !important;
}

/* BODY */
* {
  outline: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

/* HTML ELEMENT */
img {
  max-width: 100%;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

/* CUSTOM CLASSES */
.overflow {
  overflow: hidden;
}

/* LINKS */
a {
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  color: #212121;
}

a:hover {
  text-decoration: underline;
}

/* CUSTOM CONTAINER */
/* FORM ELEMENTS */
input[type=text] {
  width: 100%;
  height: 54px;
  border: 1px solid #eee;
  padding: 0 20px;
}

textarea {
  width: 100%;
  height: 160px;
  border: 1px solid #eee;
  padding: 15px 20px;
}

button[type=submit] {
  height: 54px;
  border: none;
  background: #9f8054;
  color: #fff;
  padding: 0 30px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

/* ODOMETER */
.odometer {
  line-height: 1;
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
  padding: 0;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
  padding: 0;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  left: 0;
}

/* ACCORDION */
.accordion {
  width: 100%;
  float: left;
  background: #fff;
  padding: 0;
}
.accordion .card {
  background: none;
  border-radius: 0;
  margin-top: -1px;
  border: 1px solid #eaebee;
}
.accordion .card .card-header {
  background: none;
  padding: 0;
  border-bottom: none;
}
.accordion .card .card-header a {
  width: 100%;
  float: left;
  font-weight: 500;
  padding: 20px 25px;
  color: #212121;
  font-weight: 600;
}
.accordion .card .card-header a:before {
  content: "+";
  float: left;
  text-align: center;
  margin-right: 8px;
  color: #212121;
  font-weight: 600;
}
.accordion .card .card-header a:hover {
  color: #9f8054;
  text-decoration: none;
}
.accordion .card .card-body {
  width: 100%;
  border-top: 1px solid #eee;
  line-height: 26px;
}
.accordion .card [aria-expanded=true] {
  color: #32A2FF;
}
.accordion .card [aria-expanded=true] > i {
  transform: rotate(180deg);
}

/* PAGINATION */
.pagination {
  display: flex;
}
.pagination .page-item {
  display: inline-block;
  margin-right: 6px;
}
.pagination .page-item.active .page-link {
  background: #055CA4;
  color: #fff;
  border-color: transparent;
  position: relative;
  z-index: 2;
}
.pagination .page-item.active .page-link:hover {
  background: #055CA4;
  border-color: transparent;
  color: #fff;
}
.pagination .page-item .page-link {
  display: inline-block;
  border-radius: 5px;
  padding: 7px 10px;
  color: #212121;
  font-weight: 600;
  font-size: 13px;
  border: 1px solid #dee2e6;
  line-height: 1;
}
.pagination .page-item .page-link:hover {
  background: none;
  border-color: #dee2e6;
  color: #055CA4;
}

/* GALLERY SLIDER*/
.gallery-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
  text-align: center;
  margin: 30px 0;
  background: #212121;
}
.gallery-container .swiper-wrapper {
  width: 100%;
  float: left;
  position: relative;
}
.gallery-container .swiper-slide {
  width: 80%;
  margin: 0;
  opacity: 0.6;
}
.gallery-container .swiper-slide.swiper-slide-active {
  width: 80%;
  opacity: 1;
}
.gallery-container .swiper-slide img {
  width: 100%;
}
.gallery-container .gallery-pagination {
  bottom: 20px;
  position: absolute;
  z-index: 2;
}
.gallery-container .gallery-pagination .swiper-pagination-bullet {
  background: #fff;
  opacity: 0.5;
}
.gallery-container .gallery-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ebcfa7;
  opacity: 1;
}

/* VIDEO BG */
.video-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  background: #26282b;
  background: -moz-linear-gradient(161deg, #26282b 0%, #26282b 49%, #9f8054 100%);
  background: -webkit-linear-gradient(161deg, #26282b 0%, #26282b 49%, #9f8054 100%);
  background: linear-gradient(161deg, #26282b 0%, #26282b 49%, #9f8054 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#26282b", endColorstr="#9f8054", GradientType=1);
}
.video-bg video {
  min-width: 100%;
  min-height: 100%;
  float: left;
  opacity: 0.15;
}

/* NAV PILLS */
.nav-pills {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.nav-pills .nav-item {
  display: inline-block;
  margin-right: 30px;
}
.nav-pills .nav-item:last-child {
  margin-right: 0;
}
.nav-pills .nav-item .nav-link {
  background: none;
  color: #212121;
  border-bottom: 2px solid transparent;
  padding: 4px 0;
  border-radius: 0;
  font-weight: 600;
  font-size: 14px;
}
.nav-pills .nav-item .nav-link.active {
  border-bottom: 2px solid #212121;
}

/* BREADCRUMB */
.breadcrumb {
  width: 100%;
  padding: 0;
  border-radius: 0;
  background: none;
}
.breadcrumb .breadcrumb-item {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
}
.breadcrumb .breadcrumb-item:first-child:before {
  display: none;
}
.breadcrumb .breadcrumb-item:before {
  content: "»";
  color: #fff;
  font-weight: 400;
  padding: 0 10px;
}
.breadcrumb .breadcrumb-item.active {
  color: #fff;
  opacity: 1;
}
.breadcrumb .breadcrumb-item a {
  color: #fff;
  opacity: 0.75;
}

/* HAMBURGER */
.hamburger {
  position: relative;
  cursor: pointer;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.hamburger span {
  display: block;
  height: 2px;
  width: 30px;
  background: #fff;
  opacity: 1;
  position: absolute;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.hamburger span:nth-child(1) {
  top: 0;
}
.hamburger span:nth-child(2) {
  top: 9px;
}
.hamburger span:nth-child(3) {
  width: 10px;
  top: 19px;
}
.hamburger span:nth-child(4) {
  width: 6px;
  top: 19px;
  left: 15px;
}
.hamburger.open {
  margin: 0;
}
.hamburger.open span:nth-child(1) {
  top: 9px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.hamburger.open span:nth-child(2) {
  opacity: 0;
  left: 20px;
}
.hamburger.open span:nth-child(3) {
  width: 30px;
  top: 9px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.hamburger.open span:nth-child(4) {
  opacity: 0;
  left: 20px;
}
.hamburger:hover span:nth-child(4) {
  width: 20px;
  left: 10px;
}

/* PRELAODER */
.preloader {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 12;
  background: #ebcfa7;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transition-duration: 700ms;
  -webkit-transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-delay: 0.95s;
  overflow: hidden;
}
.preloader * {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transition-duration: 700ms;
  -webkit-transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.preloader .layer {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: #333;
  transition-delay: 0.3s;
}
.preloader .inner {
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 2;
}
.preloader .inner figure {
  display: block;
  margin-bottom: 10px;
  transition-delay: 0.1s;
}
.preloader .inner figure img {
  height: 60px;
}
.preloader .inner p {
  font-weight: 600;
  color: #fff;
  font-size: 13px;
  display: block;
}

/* TRANSITION OVERLAY */
.transition-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: -100%;
  top: 0;
  z-index: 12;
  background: #ebcfa7;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transition-duration: 700ms;
  -webkit-transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  overflow: hidden;
}
.transition-overlay .layer {
  width: 100%;
  height: 100vh;
  position: absolute;
  right: -100%;
  top: 0;
  background: #333;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transition-duration: 700ms;
  -webkit-transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-delay: 0.6s;
}
.transition-overlay.active {
  left: 0;
}
.transition-overlay.active .layer {
  right: 0;
}

/* PAGE LOADED */
.page-loaded .preloader {
  right: -100%;
}

.page-loaded .preloader .inner figure {
  opacity: 0;
  transform: scale(1.5);
}

.page-loaded .preloader .inner p {
  transform: translateY(20px);
  opacity: 0;
}

.page-loaded .preloader .layer {
  left: -100%;
}

.page-loaded .navbar .container .upper-side {
  transform: translateY(0);
}

.page-loaded .navbar .container .menu {
  opacity: 1;
}

.page-loaded .slider .slider-container {
  transform: scale(1);
  opacity: 1;
}

/* SIDE NAVIGATION */
.side-navigation {
  width: 400px;
  max-width: 100%;
  height: 100%;
  min-height: calc(100vh - 74px);
  min-height: 400px;
  position: fixed;
  left: -100%;
  top: 74px;
  background: #212121;
  z-index: 10;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 14px;
  padding: 100px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transition-duration: 700ms;
  -webkit-transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 999;
  overflow-y: scroll;
}
@media (max-width: 575.98px) {
  .side-navigation {
    overflow-y: scroll;
  }
}
.side-navigation .menu {
  margin-bottom: 20px;
}
.side-navigation .menu ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.side-navigation .menu ul li {
  width: 100%;
  display: block;
  margin: 0;
  padding: 8px 0;
  list-style: none;
  position: relative;
}
.side-navigation .menu ul li ul {
  width: 100%;
  display: none;
  padding-left: 20px;
  margin-bottom: 10px;
}
.side-navigation .menu ul li ul li {
  display: block;
}
.side-navigation .menu ul li ul li a {
  font-size: 2vw;
}
.side-navigation .menu ul li a:not(.btn) {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.side-navigation .menu ul li a:not(.btn):hover {
  color: #a4d6ff;
  text-decoration: none;
}
.side-navigation .menu ul li.active a:not(.btn) {
  color: #a4d6ff;
}
.side-navigation .menu ul li .btn {
  font-size: 14px;
  font-weight: 600;
}
.side-navigation .side-content {
  width: 100%;
  display: block;
}
.side-navigation address {
  display: block;
  margin-bottom: 20px;
}
.side-navigation h6 {
  font-weight: 600;
  font-size: 14px;
}
.side-navigation p {
  display: block;
  margin-bottom: 30px;
}
.side-navigation p a {
  color: #fff;
}
.side-navigation.active {
  left: 0;
}

/* NAVBAR */
.navbar {
  position: relative;
  z-index: 3;
  background-color: #EB3136;
  transition: 0.5s all;
  padding: 1rem;
}
.navbar:not(.navbar-transparent) {
  animation: fade-bottom 0.5s forwards;
}
.navbar.navbar-transparent {
  background: transparent !important;
}
.navbar.navbar-transparent:after {
  display: none;
}
.navbar.navbar-transparent .navbar-brand .bw {
  display: block;
}
.navbar.navbar-transparent .navbar-brand .normal {
  display: none;
}
.navbar.navbar-transparent .navbar-brand:after {
  opacity: 0;
}
.navbar:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  background-image: url(../images/pattern_bg.svg);
  background-size: 300px;
  background-blend-mode: color;
  background-color: #EB3136;
  z-index: -1;
}
.navbar .navbar-nav {
  width: 100%;
  justify-content: end;
  padding: 0 2vw;
  gap: 1.25vw;
}
.navbar .navbar-nav .nav-item {
  margin: 0;
  margin-left: 1rem;
  position: relative;
  align-self: center;
}
.navbar .navbar-nav .nav-item .nav-link {
  position: relative;
  font-weight: 600;
  color: #fff;
  display: inline-block;
  padding: 0;
  font-size: 1.15vw;
  white-space: nowrap;
  opacity: 0.75;
}
.navbar .navbar-nav .nav-item .nav-link:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 2px;
  background-color: #fff;
}
.navbar .navbar-nav .nav-item .nav-link:hover:after {
  content: "";
  animation: 0.5s toHalfWidth forwards cubic-bezier(0.075, 0.82, 0.165, 1);
}
.navbar .navbar-nav .nav-item .btn {
  font-size: 1.15vw;
  padding: 0.675rem 1rem;
}
.navbar .navbar-nav .nav-item.active .nav-link {
  font-weight: 700;
  opacity: 1;
}
.navbar .navbar-nav .nav-item.active .nav-link:after {
  opacity: 1;
  width: 50%;
}
.navbar .navbar-brand {
  position: relative;
  margin-left: 1rem;
}
.navbar .navbar-brand img {
  height: 50px;
  position: relative;
  z-index: 1;
  transition: 0.5s all;
}
.navbar .navbar-brand .bw {
  display: none;
}
.navbar .navbar-brand .normal {
  display: block;
  transform: translateY(8px);
}
.navbar .navbar-brand:after {
  content: "";
  position: absolute;
  background: #fff;
  height: 85px;
  width: 120%;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px 12px 0 0;
}
.navbar .navbar-toggler {
  margin: auto;
  margin-right: 0;
  height: 20px;
}

/* SLIDER */
.slider {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-wrap: wrap;
  background: #212121;
  overflow: hidden;
}
@media (min-width: 768px) {
  .slider {
    height: calc(100vh - 94px);
  }
}
.slider .slider-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transition-duration: 700ms;
  -webkit-transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transform: scale(1.3);
  transition-delay: 1.25s;
  opacity: 0;
}
.slider .slider-container .swiper-slide {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.slider .slider-container .swiper-slide:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(161deg, #26282b 0%, #26282b 49%, #303030 100%);
  opacity: 0.35;
}
.slider .slider-container .swiper-slide .container {
  position: relative;
  z-index: 9;
  padding-left: 100px;
}
@media (max-width: 767.98px) {
  .slider .slider-container .swiper-slide .container {
    text-align: center;
  }
}
.slider .slider-container .swiper-slide .container h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  font-family: "Playfair Display", serif;
}
@media (min-width: 768px) {
  .slider .slider-container .swiper-slide .container h2 {
    margin-bottom: 20px;
  }
}
.slider .slider-container .swiper-slide .container p {
  color: #fff;
  opacity: 0.75;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .slider .slider-container .swiper-slide .container p {
    margin-bottom: 25px;
    max-width: 70%;
    font-size: 1.25rem;
  }
}
.slider .slider-container .swiper-slide .container .btn {
  font-size: 1rem;
  padding: 0.65rem 2rem;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  border-radius: 7px;
  font-family: "Playfair Display", serif;
}
.slider .slider-container .swiper-slide .container figure {
  float: left;
  margin-left: 40px;
  margin-bottom: 0;
}
.slider .slider-container .swiper-slide .container figure img {
  height: 60px;
}
.slider .slider-container .inner-elements {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.slider .slider-container .inner-elements .pagination {
  width: auto;
  position: absolute;
  left: auto;
  right: 1rem;
  bottom: 17%;
  z-index: 10;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 50px;
}
@media (min-width: 768px) {
  .slider .slider-container .inner-elements .pagination {
    bottom: 20px;
    top: unset;
  }
}
.slider .slider-container .inner-elements .pagination .swiper-pagination-current {
  font-size: 40px;
  font-weight: 800;
  margin-right: 5px;
}
.slider .slider-container .inner-elements .pagination .swiper-pagination-total {
  font-size: 20px;
  margin-left: 5px;
}
.slider .slider-container .inner-elements .button-prev {
  width: 80px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
  transition: 0.25s ease-in-out;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  font-size: 2rem;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider .slider-container .inner-elements .button-prev:hover {
  color: pink;
}
.slider .slider-container .inner-elements .button-prev:hover:after {
  background: linear-gradient(to right, #00000069, transparent);
}
.slider .slider-container .inner-elements .button-prev:after {
  content: "";
  position: absolute;
  height: 100%;
  left: 0;
  width: 80px;
  transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: linear-gradient(to right, #00000033, transparent);
}
.slider .slider-container .inner-elements .button-next {
  width: 80px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
  transition: 0.25s ease-in-out;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  font-size: 2rem;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider .slider-container .inner-elements .button-next:hover {
  color: pink;
}
.slider .slider-container .inner-elements .button-next:hover:after {
  background: linear-gradient(to left, #00000069, transparent);
}
.slider .slider-container .inner-elements .button-next:after {
  content: "";
  position: absolute;
  height: 100%;
  right: 0;
  width: 80px;
  transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background: linear-gradient(to left, #00000033, transparent);
}

/* PAGE HEADER */
.page-header {
  width: 100%;
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1080px;
}
@media (min-width: 768px) {
  .page-header {
    height: 500px;
    background-position: center;
    background-size: cover;
  }
}
.page-header:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(161deg, #26282b 0%, #26282b 49%, #303030 100%);
  opacity: 0.35;
}
.page-header .container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 2;
  padding-left: 5vw;
}
.page-header .container h1 {
  font-size: 4vw;
  color: #fff;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
  margin-bottom: 0;
}
.page-header .container p {
  display: block;
  color: #fff;
  opacity: 0.75;
  font-size: 0.875rem;
  margin: 0;
}
@media (min-width: 768px) {
  .page-header .container p {
    font-size: 1rem;
  }
}
.page-header .breadcrumb {
  position: absolute;
  width: 90%;
  margin: 5vw;
  top: 92.5%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 1rem 2rem;
  background: linear-gradient(to left, #3e3e3e, #212121);
  z-index: 3;
}
@media (min-width: 768px) {
  .page-header .breadcrumb {
    padding: 2rem 3rem;
    padding-right: 60px;
    top: 90%;
  }
}
.page-header .breadcrumb li {
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .page-header .breadcrumb li {
    font-size: 14px;
  }
}

/* INTRO */
.intro {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}
.intro .img-box {
  position: relative;
  margin-bottom: 2rem;
}
.intro .img-box img {
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .intro .img-box img {
    width: 80%;
  }
}
.intro .img-box:after {
  content: "";
  position: absolute;
  height: 110%;
  width: 80%;
  background: whitesmoke;
  left: 50%;
  top: -5%;
  transform: translateX(-50%);
  z-index: -1;
}
@media (min-width: 768px) {
  .intro .img-box:after {
    height: 100%;
    top: 5%;
    left: 5%;
    transform: none;
  }
}
.intro .content-box h3 {
  font-weight: 600;
  margin-bottom: 30px;
}
.intro .content-box p {
  display: block;
  margin-bottom: 30px;
}

/* LOGOS */
.logos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 100px 0;
}
.logos * {
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.logos .col-lg-2 {
  border-right: 1px dashed #eee;
}
.logos .col-lg-2:last-child {
  border-right: none;
}
.logos figure {
  width: 100%;
  margin: 0;
  position: relative;
}
.logos figure:hover img {
  opacity: 0;
  transform: scale(1.2);
}
.logos figure:hover h6 {
  opacity: 1;
  transform: scale(1);
}
.logos figure img {
  width: 100%;
}
.logos figure h6 {
  width: 100%;
  height: 20px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  text-align: center;
  opacity: 0;
  font-weight: 800;
  font-size: 13px;
  transform: scale(1.1);
}

/* BENEFITS */
.benefits {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  background: #f9f9f9;
  text-align: center;
}
.benefits .col {
  border-right: 1px solid #eee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.benefits .col:nth-child(odd) {
  margin-top: 50px;
}
.benefits .col:hover figure:after {
  opacity: 1;
}
.benefits .col:last-child {
  border-right: none;
}
.benefits b {
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.benefits h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.benefits h4 span {
  color: #9f8054;
}
.benefits h3 {
  font-weight: 600;
  margin-bottom: 50px;
}
.benefits figure {
  display: block;
  margin-bottom: 20px;
  position: relative;
}
.benefits figure:after {
  content: "";
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 0;
  background: #ebcfa7;
  border-radius: 50%;
  opacity: 0;
  z-index: 0;
  margin-left: -35px;
}
.benefits figure img {
  height: 70px;
  position: relative;
  z-index: 2;
}
.benefits h6 {
  font-weight: 600;
  font-size: 14px;
}
.benefits .odometer {
  font-size: 50px;
  line-height: 1;
  font-weight: 800;
}
.benefits .extra {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  transform: translateY(10px);
}

.review {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  position: relative;
  background: linear-gradient(0.86deg, #cc2024 0.86%, #eb3136 99.38%);
  color: #fff;
}
.review:after, .review:before {
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
  background-image: url("data:image/svg+xml,%3Csvg width='150' height='150' viewBox='0 0 150 150' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:multiply' clip-path='url(%23clip0_50_246)'%3E%3Cpath d='M64.8092 13.168C38.2289 15.0357 0.021 19.2795 0 71.3358V136.832H59.313V66.7558H39.6183C38.3706 48.0442 53.7787 43.2126 70.5342 39.5037L64.8092 13.168V13.168ZM144.275 13.168C117.694 15.0357 79.4865 19.2796 79.4656 71.3358V136.832H138.779V66.7558H119.084C117.836 48.0442 133.244 43.2126 150 39.5037L144.275 13.168V13.168Z' fill='%23EC363B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_50_246'%3E%3Crect width='150' height='150' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}
.review:after {
  top: 0;
  left: 0.75rem;
}
.review:before {
  bottom: 0;
  right: 0.75rem;
  transform: rotate(180deg);
}
.review .card {
  border-color: rgba(255, 255, 255, 0.24);
}
.review .avatar {
  height: 100px;
  width: 100px;
  margin-bottom: 0.5rem;
  overflow: hidden;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.review h4 {
  font-weight: 700;
}
.review p {
  font-size: 14px;
}

/* RECENT GALLERY */
.recent-gallery,
.services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.recent-gallery b,
.services b {
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.recent-gallery h4,
.services h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.recent-gallery h4 span,
.services h4 span {
  color: #9f8054;
}
.recent-gallery h3,
.services h3 {
  font-weight: 600;
  padding-right: 40%;
  margin-bottom: 30px;
}
.recent-gallery .link,
.services .link {
  display: inline-block;
  color: #212121;
  font-weight: 600;
  font-size: 14px;
  position: relative;
  padding-bottom: 10px;
}
.recent-gallery .link i,
.services .link i {
  margin-left: 10px;
}
.recent-gallery .link:hover,
.services .link:hover {
  text-decoration: none;
}
.recent-gallery .link:hover:before,
.services .link:hover:before {
  width: 100%;
}
.recent-gallery .link:before,
.services .link:before {
  content: "";
  width: 60px;
  height: 2px;
  background: #212121;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.recent-gallery figure,
.services figure {
  display: block !important;
  margin: 0;
  position: relative;
}
.recent-gallery .col-md-4:nth-child(1),
.services .col-md-4:nth-child(1) {
  margin-top: 100px;
}
.recent-gallery .col-md-4:nth-child(2),
.services .col-md-4:nth-child(2) {
  margin-top: 0;
}
.recent-gallery .col-md-4:nth-child(3),
.services .col-md-4:nth-child(3) {
  margin-top: 80px;
}

/* PROPERTY CALCULATOR */
.property-calculator {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 150px;
  margin-top: 100px;
  position: relative;
}
.property-calculator figure {
  display: inline-block;
  margin-bottom: 0;
  margin-top: -20px;
  background: #ebcfa7;
  position: relative;
}
.property-calculator figure:before {
  content: "";
  width: 50vw;
  height: 100%;
  background: #ebcfa7;
  position: absolute;
  left: -50vw;
  top: 0;
}
.property-calculator figure .pattern-bg {
  width: 120%;
  height: 100%;
  position: absolute;
  left: 5%;
  top: -5%;
  background: url(../images/side-pattern-bg.png);
  background-size: contain;
}
.property-calculator figure .holder {
  position: relative;
  width: 100%;
  float: left;
  display: block !important;
  transform: translate(85px, -35px);
}
.property-calculator figure .holder img {
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
  width: 525px;
}
.property-calculator .content-box {
  display: block;
  padding-left: 10%;
}
.property-calculator .content-box b {
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.property-calculator .content-box h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.property-calculator .content-box h4 span {
  color: #9f8054;
}
.property-calculator .content-box h3 {
  font-weight: 600;
  margin-bottom: 30px;
}
.property-calculator .content-box ul {
  display: block;
  margin-bottom: 60px;
  padding: 0;
}
.property-calculator .content-box ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.property-calculator .content-box ul li:nth-child(1) {
  margin-right: 30px;
}
.property-calculator .content-box ul li img {
  height: 35px;
}
.property-calculator .content-box p {
  display: block;
  padding-right: 40%;
  margin-bottom: 30px;
}
.property-calculator .content-box a {
  display: inline-block;
  color: #212121;
  font-weight: 600;
}
.property-calculator .content-box a img {
  height: 46px;
  margin-right: 10px;
}
.property-calculator .content-box a:hover {
  color: #9f8054;
  text-decoration: none;
}

/* PROPERTY PLANS */
.property-plans {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 150px;
}
.property-plans b {
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.property-plans h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.property-plans h4 span {
  color: #9f8054;
}
.property-plans h3 {
  font-weight: 600;
  margin-bottom: 30px;
}
.property-plans p {
  display: block;
  padding-right: 40%;
  margin-bottom: 30px;
}
.property-plans table {
  display: table;
  margin-bottom: 30px;
  font-size: 15px;
}
.property-plans table tr td {
  padding: 5px 0;
}
.property-plans table tr td:first-child {
  color: #9f8054;
  width: 120px;
  font-weight: 600;
}
.property-plans .tab-nav {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.property-plans .tab-nav li {
  display: inline-block;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  list-style: none;
}
.property-plans .tab-nav li a {
  color: #212121;
  font-weight: 600;
}

/* GET CONSULTATION */
.get-consultation {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 150px;
  margin-bottom: 100px;
  background-size: auto 120%;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #e5e4df;
}
.get-consultation .content-box {
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 80px 50px;
  margin-bottom: -100px;
  color: #fff;
  background: #9f8054;
  position: relative;
  overflow: hidden;
}
.get-consultation .content-box:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100px 200px 100vh 0;
  border-color: transparent #ebcfa7 transparent transparent;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.15;
}
.get-consultation b {
  width: 100%;
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.get-consultation h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.get-consultation h4 span {
  color: #ebcfa7;
}
.get-consultation h3 {
  font-weight: 600;
  margin-bottom: 30px;
}
.get-consultation p {
  display: block;
  padding-right: 20%;
  margin-bottom: 40px;
}
.get-consultation a {
  height: 60px;
  line-height: 60px;
  float: left;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
  position: relative;
  background: #212121;
  padding: 0 40px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.get-consultation a i {
  margin-left: 10px;
}
.get-consultation a:hover {
  text-decoration: none;
  background: #fff;
  color: #9f8054;
}

/* RECENT POSTS */
.recent-posts {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.recent-posts b {
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.recent-posts h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.recent-posts h4 span {
  color: #9f8054;
}
.recent-posts small {
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 40px;
  opacity: 0.5;
}
.recent-posts small:after {
  content: "";
  width: 50px;
  height: 1px;
  background: #212121;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 4px;
  opacity: 0.5;
}
.recent-posts .post-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.recent-posts .post-box figure {
  display: block;
  margin-bottom: 20px;
}
.recent-posts .post-box h6 {
  display: block;
  font-size: 20px;
  font-weight: 600;
}
.recent-posts .post-box h6 a {
  display: inline-block;
}
.recent-posts .post-box h6 a:hover {
  color: #9f8054;
  text-decoration: none;
}
.recent-posts .post-box p {
  display: block;
  margin: 0;
}
.recent-posts .post-box span {
  width: 100%;
  display: block;
  font-size: 13px;
  opacity: 0.5;
  margin-bottom: 10px;
}

/* PROPERTY CUSTOMIZATION */
.property-customization {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  background: #212121;
  color: #fff;
  position: relative;
}
.property-customization b {
  width: 100%;
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.property-customization h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.property-customization h4 span {
  color: #ebcfa7;
}
.property-customization h3 {
  font-weight: 600;
  margin-bottom: 30px;
}
.property-customization figure {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 10px;
  margin: 15px 0;
}
.property-customization figure img {
  height: 40px;
  float: left;
  margin-right: 15px;
}
.property-customization figure figcaption {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}

/* APARTMENTS */
.apartment {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.apartment h2 {
  font-size: 4vw;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
}
.apartment h2 span {
  color: #9f8054;
}
.apartment h6 {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 20px;
}
.apartment h5 {
  margin-bottom: 70px;
  font-weight: 600;
  opacity: 0.5;
}
.apartment h4 {
  margin-bottom: 20px;
  font-weight: 600;
}
.apartment p {
  line-height: 1.7;
  margin-bottom: 20px;
}
.apartment p strong {
  font-weight: 600;
}
.apartment ul {
  width: 100%;
  display: block;
  margin-bottom: 30px;
  padding-left: 20px;
}
.apartment ul li {
  margin: 0;
  padding: 4px 0;
}
.apartment blockquote {
  display: inline-block;
  padding: 30px;
  background: #ebcfa7;
  margin-bottom: 40px;
}
.apartment blockquote p {
  margin: 0;
}
.apartment blockquote strong {
  font-size: 20px;
}
.apartment figure {
  width: 100%;
  display: block;
  margin-bottom: 70px;
  margin-top: 50px;
}
.apartment .gallery-slider {
  width: 80%;
  margin-top: 50px;
  margin-bottom: 70px;
}
.apartment .gallery-slider .gallery-container {
  margin: 0;
}
.apartment .property-plan {
  display: inline-block;
}
.apartment .property-infos {
  display: inline-block;
  background: #9f8054;
  color: #fff;
  padding: 50px;
  margin-bottom: 50px;
  float: left;
  margin-right: 100px;
}
.apartment .property-infos .odometer {
  font-size: 60px;
  font-weight: 800;
  position: relative;
  display: inline-flex;
}
.apartment .property-infos .odometer:after {
  content: attr(data-text);
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 5px;
  opacity: 0.5;
}
.apartment .property-infos p:last-child {
  margin-bottom: 0;
}

/* ABOUT CONTENT */
.about-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 80px 0;
}
.about-content h2 {
  font-size: 4vw;
  margin-bottom: 20px;
  font-weight: 700;
}
.about-content h2 span {
  color: #9f8054;
}
.about-content h6 {
  margin-bottom: 15px;
  font-size: 20px;
}
.about-content h5 {
  margin-bottom: 40px;
  opacity: 0.5;
}
.about-content h4 {
  margin-bottom: 20px;
}
.about-content p {
  line-height: 1.7;
  margin-bottom: 20px;
}
.about-content p strong {
  font-weight: 600;
}
.about-content ul {
  width: 100%;
  display: block;
  margin-bottom: 30px;
  padding-left: 20px;
}
.about-content ul li {
  margin: 0;
  padding: 4px 0;
}
.about-content blockquote {
  display: inline-block;
  padding: 30px;
  background: #ebcfa7;
  margin-bottom: 40px;
}
.about-content blockquote p {
  margin: 0;
}
.about-content blockquote strong {
  font-size: 20px;
}
.about-content .video-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  margin-top: 30px;
}
.about-content .video-content video {
  width: 100%;
  height: 100%;
  float: left;
}
.about-content .gallery-container {
  margin-bottom: 50px;
}
.about-content .card-with-icon {
  border: none;
  transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.about-content .card-with-icon:hover {
  transform: translateY(-10px);
  background: #f6fbff;
  box-shadow: 0 10px 20px #5d4ed314;
}

/* CERTIFICATES */
.certificates {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.certificates b {
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.certificates h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.certificates h4 span {
  color: #9f8054;
}
.certificates small {
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 40px;
  opacity: 0.5;
}
.certificates small:after {
  content: "";
  width: 50px;
  height: 1px;
  background: #212121;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 4px;
  opacity: 0.5;
}
.certificates figure {
  display: block;
  margin: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.certificates figure img {
  width: 100%;
}

/* FACILITIES */
.facilities {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 135px 0;
}
.facilities b {
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.facilities h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.facilities h4 span {
  color: #9f8054;
}
.facilities small {
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 40px;
  opacity: 0.5;
}
.facilities small:after {
  content: "";
  width: 50px;
  height: 1px;
  background: #212121;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 4px;
  opacity: 0.5;
}
.facilities figure {
  width: 100%;
  float: left;
  margin: 15px 0;
  padding: 50px 30px;
  box-shadow: 0 0 20px rgba(235, 207, 167, 0.4);
}
.facilities figure img {
  height: 70px;
  float: left;
}
.facilities figure figcaption {
  width: calc(100% - 90px);
  float: right;
}
.facilities figure figcaption h5 {
  font-weight: 600;
  font-size: 16px;
}
.facilities figure figcaption p {
  font-size: 14px;
  opacity: 0.5;
  margin: 0;
}

/* FAQ */
.faq {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.faq .sidebox {
  width: calc(100% - 70px);
  float: left;
  margin-left: 70px;
  padding: 40px 30px;
  text-align: center;
  background: #9f8054;
  color: #fff;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}
.faq .sidebox i {
  font-size: 100px;
  margin-bottom: 20px;
  display: inline-block;
}
.faq .sidebox h3 {
  display: block;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
}
.faq .sidebox p {
  color: #fff;
}

/* SALES OFFICES */
.sales-offices {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 135px 0;
}
.sales-offices .office-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  margin: 15px 0;
  border: 1px solid #eee;
}
.sales-offices .office-box h5 {
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
  font-size: 26px;
}
.sales-offices .office-box address {
  margin-bottom: 25px;
}
.sales-offices .office-box a {
  height: 44px;
  line-height: 44px;
  display: inline-block;
  padding: 0 20px;
  background: #212121;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
}
.sales-offices .office-box a i {
  margin-right: 7px;
}
.sales-offices .office-box a:hover {
  text-decoration: none;
}

/* INTRO VIDEO */
.intro-video {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  text-align: center;
}
.intro-video b {
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.intro-video h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.intro-video h4 span {
  color: #9f8054;
}
.intro-video h3 {
  font-weight: 600;
  margin-bottom: 50px;
}
.intro-video .video-container {
  width: 100%;
  float: left;
  margin: 0;
  padding: 5px;
  background: #ebcfa7;
}
.intro-video .video-container video {
  width: 100%;
  float: left;
}

/* PRESS RELEASES */
.press-relases {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 135px 0;
}
.press-relases figure {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 15px 0;
  border: 1px solid #eee;
}
.press-relases figure a {
  width: 40%;
}
.press-relases figure a img {
  width: 100%;
}
.press-relases figure figcaption {
  width: 60%;
  padding-left: 20px;
}
.press-relases figure figcaption h5 {
  display: block;
  font-size: 22px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.press-relases figure figcaption p {
  font-size: 14px;
}
.press-relases figure figcaption small {
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0;
  opacity: 0.5;
}

/* PHOTO GALLERY */
.photo-gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.photo-gallery .gallery-filter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  padding: 0;
}
.photo-gallery .gallery-filter li {
  display: inline-block;
  margin-right: 25px;
  padding: 0;
  list-style: none;
}
.photo-gallery .gallery-filter li a {
  font-weight: 600;
  border-bottom: 2px solid transparent;
}
.photo-gallery .gallery-filter li a.current {
  border-bottom: 2px solid #ebcfa7;
}
.photo-gallery .gallery-filter li a:hover {
  color: #9f8054;
  text-decoration: none;
}
.photo-gallery .gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-left: -4px;
  margin-right: -4px;
  padding: 0;
}
.photo-gallery .gallery li {
  width: 25%;
  margin: 0;
  padding: 4px;
  list-style: none;
}
.photo-gallery .gallery li img {
  width: 100%;
  float: left;
}

/* SALES TEAM */
.sales-team {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.sales-team figure {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #eee;
  margin: 15px 0;
}
.sales-team figure img {
  width: 50%;
  display: inline-block;
}
.sales-team figure figcaption {
  display: inline-block;
}
.sales-team figure figcaption h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.sales-team figure figcaption h4 span {
  color: #9f8054;
}
.sales-team figure figcaption small {
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 40px;
  opacity: 0.5;
}
.sales-team figure figcaption small:after {
  content: "";
  width: 50px;
  height: 1px;
  background: #212121;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 4px;
  opacity: 0.5;
}
.sales-team figure figcaption ul {
  display: block;
  margin: 0;
  padding: 0;
}
.sales-team figure figcaption ul li {
  display: inline-block;
  margin: 0;
  margin-right: 15px;
  padding: 0;
  list-style: none;
}
.sales-team figure figcaption ul li a {
  font-size: 14px;
  font-weight: 600;
}
.sales-team figure figcaption ul li a i {
  margin-right: 6px;
  font-size: 12px;
  font-weight: 400;
}
.sales-team figure figcaption ul li a:hover {
  text-decoration: none;
  color: #9f8054;
}

/* ERROR 404*/
.error404 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  text-align: center;
}
.error404 figure {
  display: block;
  margin-bottom: 60px;
  padding: 0 10%;
}
.error404 figure img {
  width: 100%;
}
.error404 h2 {
  font-size: 50px;
  font-weight: 800;
  color: #9f8054;
}
.error404 p {
  display: block;
  margin-bottom: 40px;
}
.error404 a {
  height: 60px;
  line-height: 56px;
  display: inline-block;
  color: #9f8054;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
  position: relative;
  border: 2px solid #ebcfa7;
  padding: 0 40px;
  box-shadow: 0 0 20px rgba(235, 207, 167, 0.4);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.error404 a:hover {
  text-decoration: none;
  color: #fff;
  background: #9f8054;
  border-color: #9f8054;
}

/* BLOG */
.blog {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.blog .post {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
  position: relative;
  flex-direction: column;
}
.blog .post .post-content {
  width: calc(100% - 60px);
  float: left;
  background: #fff;
  margin: 0 30px;
  margin-top: -100px;
  padding: 30px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
}
.blog .post .post-content.single {
  width: 100%;
  margin: 0;
  margin-top: 50px;
  padding: 0;
}
.blog .post .post-content .post-title {
  font-weight: 700;
}
.blog .post .post-content .post-title a {
  color: #212121;
}
.blog .post .post-content .post-title a:hover {
  color: #323232;
  text-decoration: none;
}
.blog .post .post-content .post-tags {
  width: 100%;
  float: left;
}
.blog .post .post-content .post-tags i {
  font-size: 19px;
  float: left;
  margin-right: 10px;
  color: #9f8054;
  margin-top: 4px;
}
.blog .post .post-content .post-tags ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.blog .post .post-content .post-tags ul li {
  float: left;
  margin-right: 10px;
  padding: 0;
  list-style: none;
  margin-bottom: 12px;
}
.blog .post .post-content .post-tags ul li a {
  color: #212121;
  text-decoration: underline;
}
.blog .post .post-content ul {
  width: 100%;
  display: block;
  margin-bottom: 30px;
  padding-left: 20px;
}
.blog .post .post-content ul li {
  margin: 0;
  padding: 4px 0;
}
.blog .post .post-content blockquote {
  display: inline-block;
  padding: 30px;
  background: #ebcfa7;
  margin-bottom: 40px;
}
.blog .post .post-content blockquote p {
  margin: 0;
}
.blog .post .post-content blockquote strong {
  font-size: 20px;
}
.blog .post .post-content .post-intro {
  width: 100%;
  float: left;
  margin-bottom: 30px;
  font-size: 0.875rem;
  line-height: 1.6;
}
.blog .post .post-content .post-author {
  line-height: 60px;
}
.blog .post .post-content .post-author span {
  opacity: 0.5;
  margin-right: 5px;
  font-style: italic;
}
.blog .post .post-content .post-author a {
  color: #212121;
}
.blog .sidebar {
  width: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
  border-left: 1px solid #eee;
  padding-left: 50px;
  margin-bottom: 100px;
  position: sticky;
  top: 120px;
}
.blog .sidebar .widget {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}
.blog .sidebar .widget:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.blog .sidebar .widget .title {
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 20px;
}
.blog .sidebar .widget .form-control {
  border-radius: 13px 0 0 13px;
}
.blog .sidebar .widget .categories {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
}
.blog .sidebar .widget .categories li {
  width: 100%;
  display: block;
  margin: 0;
  padding: 2px 0;
  list-style: none;
}
.blog .sidebar .widget .categories li:before {
  content: "»";
  margin-right: 9px;
  color: #323232;
}
.blog .sidebar .widget .categories li span {
  float: right;
  color: #323232;
}
.blog .sidebar .widget .categories li a {
  color: #212121;
}
.blog .sidebar .widget .categories li a:hover {
  color: #323232;
}

/* CONTACT */
.contact {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.contact b {
  display: block;
  font-size: 30px;
  font-weight: 800;
  opacity: 0.2;
}
.contact h4 {
  display: block;
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}
.contact h4 span {
  color: #9f8054;
}
.contact address {
  display: block;
  margin-bottom: 50px;
}
.contact address strong {
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.contact address p {
  display: block;
  margin: 0;
}
.contact .contact-form {
  width: 100%;
  display: block;
  padding-left: 10%;
}
.contact .contact-form h5 {
  margin-bottom: 40px;
}
.contact .contact-form #contact {
  display: block;
}
.contact .contact-form #contact .form-group {
  display: block;
  position: relative;
  margin-bottom: 10px;
}
.contact .contact-form #contact .form-group input[type=text] {
  background: none;
  position: relative;
  z-index: 2;
}
.contact .contact-form #contact .form-group textarea {
  background: none;
  position: relative;
  z-index: 2;
}
.contact .contact-form #contact .form-group label.error {
  color: red;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 13px;
}
.contact .contact-form .alert {
  border: none;
  border-radius: 0;
  padding: 20px 30px;
}
.contact .contact-form .btn {
  padding: 1rem 2rem;
  display: inline-flex;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.contact .contact-form .spinner-wrapper {
  margin-left: 0.5rem;
  margin-right: -10px;
}

/* FOOTER BAR */
.float-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 120px;
  padding-bottom: 100px;
}

.main-card {
  margin-top: -90px;
  background: transparent;
  margin-bottom: 2rem;
}
.main-card .card-header {
  border-radius: 13px 13px 0 0;
}
.main-card .card-header ul {
  margin: 0;
  padding: 0.5rem 0;
}
@media (max-width: 575.98px) {
  .main-card .card-header ul {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .main-card .card-header ul::-webkit-scrollbar {
    height: 3px;
  }
  .main-card .card-header ul::-webkit-scrollbar-track {
    background: #CD171B;
    border-radius: 10px;
  }
  .main-card .card-header ul::-webkit-scrollbar-thumb {
    background: #ad2124;
    border-radius: 10px;
  }
}
.main-card .card-header ul li {
  position: relative;
  padding: 0 1rem 0 0.25rem;
  list-style: none;
}
.main-card .card-header ul li:not(:last-child) > :after {
  content: "";
  position: absolute;
  height: 1rem;
  width: 2px;
  background-color: white;
  opacity: 0.75;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
}
.main-card .card-header ul li a {
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  opacity: 0.75;
}
.main-card .card-header ul li.active a {
  opacity: 1;
}
.main-card .card-header ul li.active:after {
  content: "";
  position: absolute;
  height: 4px;
  width: 80%;
  background: #ffb4b6;
  left: 45%;
  transform: translateX(-50%);
  bottom: -25px;
}
.main-card .main-card-body {
  border-radius: 0 0 13px 13px;
  background-color: white;
  padding: 3vmin 4vmin;
  transform-origin: top;
  transition: 0.2s all;
}
.main-card .main-card-body.skeleton {
  padding-top: 10rem;
}
.main-card .main-card-body.skeleton > * {
  display: none;
}

.form-with-input-group .form-group .btn {
  width: 100%;
}
@media (min-width: 768px) {
  .form-with-input-group .form-group .btn {
    width: unset;
  }
}
@media (min-width: 768px) {
  .form-with-input-group .input-group .input-group-text {
    background: #EB3136;
    color: #fff;
  }
}
@media (max-width: 767.98px) {
  .form-with-input-group .input-group {
    flex-flow: column;
  }
  .form-with-input-group .input-group .input-group-text {
    background: none;
    padding: 0;
  }
  .form-with-input-group .input-group .form-control,
.form-with-input-group .input-group .btn {
    border-radius: 12px;
  }
  .form-with-input-group .input-group .input-group-prepend,
.form-with-input-group .input-group .form-control {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .form-with-input-group .input-group .btn {
    width: 100%;
    margin-top: 0.25rem;
    padding: 12px;
  }
}

.agent-row {
  max-height: 400px;
  overflow-y: scroll !important;
  overflow: hidden;
}
.agent-row::-webkit-scrollbar {
  width: 3px;
}
.agent-row::-webkit-scrollbar-track {
  background: #F2F2F2;
  border-radius: 10px;
}
.agent-row::-webkit-scrollbar-thumb {
  background: #E5E5E5;
  border-radius: 10px;
}

.media {
  flex-direction: column;
  padding: 2rem 1rem;
  border-radius: 12px;
}
.media img {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .media img {
    width: 50%;
    max-width: 300px;
  }
}
.media .nav-tabs {
  padding: 0;
  border: 0;
}
.media .nav-tabs .nav-link {
  border: 0;
  display: inline-flex;
  border-radius: 4px;
}
.media .nav-tabs .nav-link.active {
  background-color: #055CA4;
  color: #fff;
}
.media p {
  margin-bottom: 0.5rem;
}
.media .tab-pane p {
  margin-bottom: 0.5rem;
}
.media.service {
  border: 1px solid #e9e9e9;
}
@media (min-width: 768px) {
  .media.service {
    flex-direction: row;
  }
}
.media.service img {
  width: 100%;
}
@media (min-width: 768px) {
  .media.service img {
    width: 50%;
    max-width: 300px;
    margin-right: 1rem;
  }
}
.media.service p {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .media.service p {
    max-width: 75%;
  }
}

a[href] {
  cursor: pointer;
}

.card-agent {
  height: 100%;
  background-color: #fff;
  border: 0;
  border-radius: 8px;
}
.card-agent .card-body {
  padding: 1rem;
}
.card-agent .card-body h6 {
  font-size: 0.875rem;
  font-weight: 600;
}
.card-agent .card-footer {
  padding-top: 0;
  background: #f7f7f742;
  border: 0;
}
.card-agent .card-footer .btn {
  padding: 0.5rem 0.75rem;
  color: #fff;
}

.slider-promo,
.slider-services {
  width: 100%;
  height: 100%;
}

.slider-promo .splide__arrows {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
  z-index: 9;
}
.slider-promo .splide__pagination {
  bottom: 10%;
}
@media (min-width: 768px) {
  .slider-promo .splide__pagination {
    bottom: 15%;
  }
}
.slider-promo .splide__pagination .splide__pagination__page {
  background: rgba(235, 49, 54, 0.4);
}
.slider-promo .splide__pagination .splide__pagination__page.is-active {
  background: #CD171B;
}
.slider-promo .splide__arrow--prev {
  left: -1em;
}
.slider-promo .splide__arrow--next {
  right: -1em;
}

.slider-services {
  margin-bottom: 2rem;
}
.slider-services .splide__slide {
  position: relative;
}
.slider-services .splide__slide p {
  position: absolute;
  margin: 0;
  width: 100%;
  bottom: -35px;
  opacity: 0;
  left: 0%;
  text-align: center;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  padding: 1rem 0.25rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0, rgba(0, 0, 0, 0) 100%);
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.slider-services .splide__slide:hover p {
  opacity: 1;
  bottom: 0;
}

.slider-review .splide__pagination {
  bottom: -20%;
}
.slider-review .splide__pagination .splide__pagination__page {
  background: rgba(255, 255, 255, 0.4);
}
.slider-review .splide__pagination .splide__pagination__page.is-active {
  background: #fff;
}

/* FOOTER */
.footer {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom: 30px;
  background: #212121;
}
.footer:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url(../images/pattern_bg.svg);
  background-size: 300px;
  background-blend-mode: luminosity;
  background-color: #212121;
  z-index: 0;
}
.footer .container {
  z-index: 1;
}
.footer a {
  color: #D3D3D3;
}
.footer a:hover {
  color: #fff;
}
.footer p {
  color: #D3D3D3;
}
.footer .logo {
  height: 41px;
  margin-bottom: 15px;
}
.footer .logo img {
  height: 48px;
}
.footer .footer-menu {
  display: block;
  margin: 0;
  padding-left: 10px;
}
.footer .footer-menu li {
  position: relative;
  width: 100%;
  float: left;
  margin: 0;
  padding: 4px 0 4px 15px;
  list-style: none;
}
.footer .footer-menu li:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.63317 10C1.37151 9.99846 1.11311 9.94167 0.874915 9.83335C0.617295 9.71979 0.397806 9.53448 0.242662 9.29955C0.087518 9.06462 0.00327723 8.78999 0 8.50848V1.49249C0.00327723 1.21098 0.087518 0.936353 0.242662 0.701421C0.397806 0.466489 0.617295 0.281179 0.874915 0.167623C1.17134 0.02761 1.50113 -0.0263174 1.82671 0.0119863C2.15229 0.0502899 2.46057 0.179284 2.7164 0.384269L6.96599 3.89226C7.13264 4.02493 7.26722 4.19347 7.35972 4.38534C7.45222 4.57722 7.50025 4.78748 7.50025 5.00049C7.50025 5.21349 7.45222 5.42376 7.35972 5.61563C7.26722 5.8075 7.13264 5.97604 6.96599 6.10871L2.7164 9.6167C2.41008 9.86512 2.02756 10.0005 1.63317 10Z' fill='white'/%3E%3C/svg%3E");
  position: absolute;
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: 7px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.8;
}
.footer h5 {
  margin-bottom: 10px;
  font-weight: 700 !important;
}
.footer .contact-box {
  color: #fff;
}
.footer .contact-box p {
  margin-bottom: 10px;
}
.footer .contact-box p a {
  color: #fff;
  opacity: 0.5;
}
.footer .contact-box a i {
  font-size: 16px;
}
.footer .copyright-container {
  margin-top: 2rem;
  padding: 0.5rem 1rem;
}
.footer .copyright-container hr {
  margin-bottom: 2rem;
  border-top: 1.5px solid #565656;
}
.footer .copyright-container .copyright {
  color: #fff;
}
.footer .social-media {
  float: none;
  display: flex;
  justify-content: center;
  margin: 0;
}
@media (min-width: 768px) {
  .footer .social-media {
    justify-content: start;
  }
}
.footer .social-media li a {
  background: rgba(5, 91, 163, 0.5);
  font-size: 20px;
}

/* RESPONSIVE MEDIUM  FIXES */
@media only screen and (max-width: 1199px), only screen and (max-device-width: 1199px) {
  .intro figure .holder {
    transform: translate(65px, -65px);
  }

  .intro figure .holder img {
    width: 450px;
  }

  .property-calculator figure .holder {
    transform: translate(65px, -65px);
  }

  .property-calculator figure .holder img {
    width: 450px;
  }

  .footer-bar .inner p {
    font-size: 15px;
  }

  .footer-bar .inner p br {
    display: none;
  }
}
/* RESPONSIVE TABLET FIXES */
@media only screen and (max-width: 991px), only screen and (max-device-width: 991px) {
  .navbar .container .menu {
    display: none;
  }

  .page-header .container h1 {
    font-size: 28px !important;
  }

  .intro img {
    width: 100%;
    margin-bottom: 2rem;
  }

  .intro .content-box {
    padding-left: 0;
  }

  .logos img {
    padding: 0 10%;
  }

  .about-content {
    padding: 25px 0;
  }

  .sales-offices {
    padding: 80px 0;
  }

  .press-relases {
    padding: 80px 0;
  }

  .photo-gallery {
    padding: 80px 0;
  }

  .photo-gallery .gallery li {
    width: 33.33333%;
  }

  .intro-video {
    padding: 80px 0;
  }

  .faq .sidebox {
    width: 100%;
    margin-left: 0;
    margin-top: 50px;
  }

  .apartment {
    padding: 80px 0;
  }

  .apartment .property-infos {
    width: 100%;
    margin-right: 0;
  }

  .apartment .property-plan {
    margin: 50px 0;
  }

  .apartment .gallery-slider {
    width: 100%;
  }

  .facilities {
    padding: 80px 0;
  }

  .blog .sidebar {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  .sales-team {
    padding: 80px 0;
  }

  .sales-team figure figcaption {
    width: 50%;
  }

  .sales-team figure figcaption h4 {
    font-size: 21px;
  }

  .sales-team figure figcaption small {
    margin-bottom: 20px;
  }

  .error404 {
    padding: 80px 0;
  }

  .recent-gallery {
    padding: 80px 0;
  }

  .recent-gallery h3 {
    padding-right: 0;
  }

  .recent-gallery .col-md-4 {
    margin-top: 0 !important;
  }

  .recent-gallery figure {
    margin-top: 80px;
  }

  .benefits {
    padding: 80px 0;
  }

  .property-calculator {
    padding-bottom: 80px;
    margin-top: 0;
  }

  .property-calculator .col-lg-6:nth-child(1) {
    order: 2;
  }

  .property-calculator .col-lg-6:nth-child(2) {
    order: 1;
  }

  .property-calculator figure {
    width: 100%;
  }

  .property-calculator figure:before {
    display: none;
  }

  .property-calculator figure .pattern-bg {
    width: 100%;
    left: 0;
  }

  .property-calculator figure .holder {
    transform: translate(0, -45px);
    padding: 0 15%;
  }

  .property-calculator figure .holder img {
    width: 100%;
  }

  .property-calculator .content-box {
    padding-left: 0;
    margin-bottom: 150px;
  }

  .property-plans {
    padding-bottom: 80px;
  }

  .property-plans .nav-pills {
    margin-top: 40px;
  }

  .recent-posts {
    padding: 80px 0;
  }

  .recent-posts .post-box {
    margin: 25px 0;
  }

  .property-customization {
    padding: 80px 0;
  }

  .video-bg video {
    float: right;
  }

  .certificates {
    padding: 80px 0;
  }

  .contact {
    padding: 80px 0;
  }

  .contact address p a {
    display: block;
  }

  .contact .contact-form {
    padding-left: 0;
    margin-top: 50px;
  }

  .blog {
    padding: 80px 0;
  }

  .footer-bar .inner {
    padding: 30px;
  }

  .footer-bar .inner .col-lg-4 {
    margin: 20px 0;
  }

  .footer-bar .inner figure {
    width: 70px;
    margin-right: 0;
  }

  .footer-bar .inner figure img {
    height: 50px;
  }

  .footer {
    padding-top: 150px;
  }

  .footer .select-box {
    margin-bottom: 80px;
  }

  .footer .footer-menu {
    padding: 0;
  }

  .footer .contact-box {
    float: left;
    text-align: left;
  }

  .footer .contact-box ul {
    float: left;
  }
}
/* RESPONSIVE MOBILE FIXES */
@media only screen and (max-width: 767px), only screen and (max-device-width: 767px) {
  .side-navigation {
    width: 80vw;
  }

  .page-header .container h1 {
    font-size: 36px;
  }

  .page-header .container .breadcrumb {
    width: calc(100% - 30px);
  }

  .navbar .container .upper-side .phone-email {
    display: none;
  }

  .navbar .container .upper-side .language {
    display: none;
  }

  .navbar .container .upper-side .hamburger {
    margin-left: auto;
  }

  .slider .slider-container .inner-elements .container .social-media {
    display: none;
  }

  .slider .slider-container .swiper-slide .container {
    padding-left: 15px;
    padding-right: 40px;
  }

  .slider .slider-container .swiper-slide .container figure {
    width: 100%;
    margin-left: 0;
    margin-top: 30px;
  }

  .benefits .col {
    width: 50%;
    flex-basis: auto;
    border-right: none;
  }

  .benefits .col:nth-child(odd) {
    margin-top: 0;
  }

  .apartment h2 {
    font-size: 9vw;
  }

  .apartment figure {
    margin: 15px 0;
  }

  .apartment h4 {
    margin-top: 30px;
  }

  .about-content h2 {
    font-size: 10vw;
  }

  .blog .post .post-image {
    margin-bottom: 30px;
  }

  .blog .post .post-content {
    margin: 0;
    width: 100%;
  }

  .contact .map .holder iframe {
    width: 100%;
    height: 450px;
  }

  .certificates figure {
    margin: 15px 0;
  }

  .footer .col-12 .creation {
    float: left;
    margin-top: 5px;
  }
}
.heading {
  position: relative;
  font-weight: 800 !important;
  text-align: center;
}
@media (max-width: 767.98px) {
  .heading {
    font-size: 1.5rem;
  }
}

.sub-heading {
  width: 100%;
  text-align: center;
  margin: auto;
}
@media (min-width: 768px) {
  .sub-heading {
    max-width: 500px !important;
  }
}
@media (min-width: 992px) {
  .sub-heading {
    max-width: 750px !important;
  }
}

.select-box {
  display: inline-block;
  position: relative;
  height: 50px;
  line-height: 48px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding-right: 15px;
  padding-left: 25px;
  margin-top: 20px;
}
.select-box:hover {
  border: 1px solid rgba(255, 255, 255, 0.35);
}
.select-box span {
  margin-right: 40px;
}
.select-box .dropdown-toggle {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.select-box .dropdown-toggle:hover {
  text-decoration: none;
}
.select-box .dropdown-toggle img {
  width: 20px;
  margin-right: 5px;
}
.select-box .dropdown-menu {
  width: 100%;
  background: #fff;
  border-radius: 0;
  border: none;
  margin-left: -25px;
  margin-top: 13px;
}
.select-box .dropdown-menu li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.select-box .dropdown-menu li a {
  display: block;
  padding: 7px 25px;
  font-size: 14px;
  line-height: 1;
}
.select-box .dropdown-menu li a:hover {
  background: none;
  color: #9f8054;
}
.select-box .dropdown-menu li a img {
  width: 20px;
  margin-right: 5px;
}

.timeline {
  border-radius: 0;
  flex-direction: column-reverse;
}
.timeline.sm {
  max-height: 300px;
  overflow-y: scroll;
  padding-left: 1rem;
}
.timeline::-webkit-scrollbar {
  width: 5px;
  background: #D3D3D3;
  border-radius: 5px;
}
.timeline::-webkit-scrollbar-thumb {
  background: #8B8B8B;
  border-radius: 5px;
}
.timeline::-webkit-scrollbar-thumb:hover {
  background: #8B8B8B;
}
.timeline .list-group-item {
  position: relative;
  border: 0;
  border-left: 4px solid #F2F2F2;
  border-radius: 0 !important;
}
.timeline .list-group-item:after {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  top: 50%;
  left: -10px;
  animation: timeline-grow 0.75s linear;
}
.timeline .list-group-item:last-child:after {
  transform: translateY(-50%) scale(0.5);
  background: #055CA4;
  border-color: #055CA4;
  animation-iteration-count: infinite;
}
.timeline .list-group-item:after {
  transform: translateY(-50%) scale(1);
  background: #fff;
  border: 2px solid #D3D3D3;
  animation-fill-mode: forwards;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.bg-image {
  margin-bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(211, 211, 211, 0.3);
}

.ratio-16-9 {
  padding-top: 54%;
}

.ratio-1-1 {
  padding-top: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.p-center-bottom {
  background-position: center bottom;
}

.p-center-top {
  background-position: center top;
}

.p-center-center {
  background-position: center center;
}

.p-left-center {
  background-position: left center;
}

.p-left-top {
  background-position: left top;
}

.p-left-bottom {
  background-position: left bottom;
}

.p-right-center {
  background-position: right center;
}

.p-right-bottom {
  background-position: right bottom;
}

.p-right-top {
  background-position: right top;
}

.tnc {
  line-height: 1.5;
  padding-left: 2rem;
}
.tnc li {
  padding: 0.25rem;
  border-radius: 8px;
}
.tnc li:hover {
  background: rgba(211, 211, 211, 0.3);
}
.tnc li ol {
  padding-left: 1.5rem;
}
.tnc li ol li {
  list-style-type: lower-alpha;
  padding: 0.25rem;
  opacity: 0.9;
}

.card-with-icon {
  height: 100%;
}
.card-with-icon .icon {
  padding: 1rem;
  background: #055CA4;
  color: white;
  display: inline-flex;
  border-radius: 50%;
}

.skeleton {
  background: linear-gradient(90deg, #dce0e5, #f0f2f4, #dce0e5);
  background-size: 200%;
  animation: loader 1s infinite reverse;
}

h2.skeleton {
  width: 50%;
  height: 1.5em;
  border-radius: 8px;
}

small.skeleton {
  display: inline-flex;
  height: 1rem;
  width: 4rem;
  border-radius: 8px;
}

p.skeleton {
  height: 2em;
  border-radius: 8px;
}

.social-media {
  width: 100%;
  float: left;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
}
.social-media li {
  float: left;
  margin-left: 5px;
  padding: 0;
  list-style: none;
}
.social-media li a {
  width: 40px;
  height: 40px;
  float: left;
  text-align: center;
  line-height: 42px;
  font-size: 15px;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}
.social-media li a:hover {
  background: #0B5089;
}