@keyframes toHalfWidth {
    from {
        opacity: 0;
        width: 0;
    }

    to {
        opacity: 1;
        width: 50%;
    }
}

@keyframes timeline-grow {
    0% {
        opacity: .25;
    }

    50% {
        transform: translateY(-50%) scale(1);
        opacity: 1;
    }
}

@keyframes loader {
    from {
        background-position: -100% 0;
    }

    to {
        background-position: 100% 0;
    }
}

@keyframes fade-bottom {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}
