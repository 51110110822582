$color-light:#ebcfa7;
$color-brown: #9f8054;
$color-dark: #212121;
$danger: #EB3136;
$primary: #055CA4;
$dark-color: #323232;
$light-color: #D3D3D3;

$white: #fff;
$super-light: #F2F2F2;

// Main
$muted: #8B8B8B;
$default: #8B8B8B;
$light: #E5E5E5;
$success: #229E27;
$primary: #055CA4;
$info: #22889E;
$warning: #EBB731;
$danger: #EB3136;
$dark: #323232;
$link: #32A2FF;

// Hover
$muted-hover: #716E6E;
$default-hover: #716E6E;
$light-hover: #DBDBDB;
$success-hover: #108B15;
$primary-hover: #0B5089;
$info-hover: #167387;
$warning-hover: #E2B02E;
$danger-hover: #CD171B;
$dark-hover: #252525;
$link-hover: #2090ED;
